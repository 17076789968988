import { Directive, forwardRef, Inject, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';
import { isEmpty, normalizeAndCleanObject } from '../../common/helper';
import { AppService } from '../../service/app.service';
import { FieldService } from '../../service/field.service';
import { DynamicModalComponent } from '../../shared/component';
import { SearchFieldInput, SearchFieldInputType, SearchTargetType } from './search-field.component';
import { SearchFieldService } from './search-field.service';
import { SelectionInputDialogData } from './selection-input/selection-input.component';
import { ThingSelectionInputDialogComponent } from './selection-input/thing-selection-input-dialog/thing-selection-input-dialog.component';

@Directive()
export abstract class AbstractSearchFieldComponent implements OnInit, OnDestroy {

    @Input() id: string;

    @Input() searchTarget: SearchTargetType;

    @Input() label: string;

    @Input() query: { property: string, predicate: string, value: any }[];

    @Input() inputs: SearchFieldInput[];

    @Input() rangeSelectionEnabled: boolean;

    @ViewChild(DynamicModalComponent) dialog: DynamicModalComponent;

    searchControl: FormControl;
    filterOpened: boolean;
    filtered: boolean;
    advancedSearchProperties: { name: string, label: string }[];
    advancedSearchBody: any;
    propertySearchFields: string[] = [];
    selectedThingIdCount: number;

    private sub: Subscription;
    private searchSubject$: Subject<boolean>;
    private skipNextSearch: boolean;
    private isMobile: boolean;

    abstract openAddPropertiesDialog(advancedSearchProperties: { name: string, label: string }[]): void;
    abstract closeAddPropertiesDialog(updatedSearchFields: string[]): void;
    abstract performClear(): void;
    protected abstract updateAdvancedSearch(fieldsToSaveBody: any): void;
    protected abstract getRawValues(): any;
    protected abstract getEncodedRawValues(): any;
    protected abstract refreshInputConfigurations(): void;

    constructor(
        @Inject(forwardRef(() => FieldService)) private fieldService: FieldService,
        @Inject(forwardRef(() => SearchFieldService)) private searchFieldService: SearchFieldService,
        @Inject(forwardRef(() => MatDialog)) private matDialog: MatDialog,
        @Inject(forwardRef(() => ViewContainerRef)) private vcRef: ViewContainerRef,
        @Inject(forwardRef(() => AppService)) private appService: AppService
    ) {
        this.isMobile = this.appService.isMobile();
    }

    ngOnInit(): void {
        if (this.inputs?.some(input => input.type == SearchFieldInputType.KEY)) {
            this.searchControl = new FormControl();
            const savedFieldsValues = localStorage.getItem(this.id) ? JSON.parse(localStorage.getItem(this.id)) : null;
            this.searchControl.setValue(this.searchFieldService.getValueFromFieldsValues("key", savedFieldsValues));
        }
        this.setPropertySearchFields();
        this.fieldService.register(this.id, null);
        this.searchSubject$ = new Subject();
        this.sub = this.searchSubject$.asObservable().pipe(debounceTime(1500))
            .subscribe(() => {
                if (!this.skipNextSearch) {
                    this.searchInputChanged();
                } else {
                    this.skipNextSearch = false;
                }
            });
        this.initElementIdCounts();
    }

    ngOnDestroy(): void {
        if (this.sub) {
            this.sub.unsubscribe();
        }
        this.fieldService.unregister(this.id);
        this.fieldService.notify();
    }

    handleEnterKey(): void {
        this.skipNextSearch = true;
        this.searchInputChanged();
    }

    handleKeyUp(): void {
        this.searchSubject$.next(true);
    }

    performSearch(): void {
        const body = Object.assign({}, { searchTarget: this.searchTarget }, this.advancedSearchBody || null);
        this.fieldService.updateValue(this.id, body);
        this.initElementIdCounts();
    }

    close(): void {
        this.dialog?.close();
    }

    open(): void {
        this.filterOpened = true;
        this.dialog.open();
    }

    emitAdvancedSearch(advancedSearchBody: any): void {
        this.close();
        this.filtered = this.isFiltered(normalizeAndCleanObject(advancedSearchBody));
        advancedSearchBody['searchTarget'] = this.searchTarget;
        this.fieldService.updateValue(this.id, advancedSearchBody);
        this.advancedSearchBody = advancedSearchBody;
        this.initElementIdCounts();
    }

    searchInputChanged(): void {
        const key = this.searchControl?.value;
        let encodedRawValues: any = this.getEncodedRawValues();
        let rawValues: any = this.getRawValues();
        encodedRawValues['key'] = key || null;
        rawValues['key'] = key || null;
        this.advancedSearchBody = Object.assign({}, this.advancedSearchBody, encodedRawValues);
        this.filtered = this.isFiltered(normalizeAndCleanObject(this.advancedSearchBody));
        const savedFieldsValues = localStorage.getItem(this.id) ? JSON.parse(localStorage.getItem(this.id)) : null
        rawValues = Object.assign({}, savedFieldsValues, rawValues);
        this.clearSelectableInputValues(rawValues);
        this.updateAdvancedSearch(rawValues);
        this.performSearch();
    }

    refreshInputs(): void {
        const savedFieldsValues = localStorage.getItem(this.id) ? JSON.parse(localStorage.getItem(this.id)) : null;
        this.searchControl?.setValue(savedFieldsValues && savedFieldsValues['key'] ? savedFieldsValues['key'] : null);
        this.refreshInputConfigurations();
    }

    clearInputs(): void {
        this.searchControl?.reset();
        this.refreshInputConfigurations();
        this.advancedSearchBody = {};
    }

    protected setPropertySearchFields(): void {
        this.propertySearchFields = this.inputs.filter(input => input.type == SearchFieldInputType.PROPERTY).map(filtered => {
            if (filtered.property.startsWith("properties.")) {
                return filtered.property.substring(11);
            } else {
                return filtered.property;
            }
        });
    }

    private isFiltered(advancedSearch: any): boolean {
        if (advancedSearch) {
            return Object.keys(advancedSearch).filter(key => key != 'searchTarget').some(key => {
                if (isEmpty(advancedSearch[key])) {
                    return false;
                }
                if (advancedSearch[key] instanceof Array) {
                    return advancedSearch[key].length > 0;
                } else {
                    return true;
                }
            });
        } else {
            return false;
        }
    }

    private clearSelectableInputValues(rawValues: any): void {
        delete this.advancedSearchBody?.selectedThingIds;
        delete rawValues?.selectedThingIds;
        localStorage.setItem(this.id, JSON.stringify(rawValues));
    }

    private initElementIdCounts(): void {
        const savedFieldsValues = localStorage.getItem(this.id) ? JSON.parse(localStorage.getItem(this.id)) : null;
        this.selectedThingIdCount = savedFieldsValues?.selectedThingIds?.length;
    }

    openThingSelectionInput(input: SearchFieldInput): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.minWidth = this.isMobile ? '90%' : '25%';
        dialogConfig.maxWidth = '800px';
        dialogConfig.autoFocus = false;
        const data: SelectionInputDialogData = {
            columnComponents: input.columnComponents,
            queryId: this.id,
            advancedSearchBody: this.getAdvancedSearchBody()
        };
        dialogConfig.data = data;
        dialogConfig.viewContainerRef = this.vcRef;
        this.matDialog.open(ThingSelectionInputDialogComponent, dialogConfig).afterClosed().pipe(take(1)).subscribe(selectedIds => {
            if (selectedIds) {
                this.advancedSearchBody = Object.assign({}, this.advancedSearchBody, { 'selectedThingIds': selectedIds.length ? selectedIds : null });
                this.selectedThingIdCount = selectedIds.length;
                this.updateSelectedThingIdsLocalStorage(selectedIds);
                this.emitAdvancedSearch(this.advancedSearchBody);
            }
        });
    }

    private updateSelectedThingIdsLocalStorage(selectedThingIds: string[]): void {
        let savedFieldsValues = localStorage.getItem(this.id) ? JSON.parse(localStorage.getItem(this.id)) : null;
        savedFieldsValues = Object.assign({}, savedFieldsValues, { 'selectedThingIds': selectedThingIds.length ? selectedThingIds : null });
        localStorage.setItem(this.id, JSON.stringify(savedFieldsValues));
    }

    private getAdvancedSearchBody(): void {
        switch (this.searchTarget) {
            case SearchTargetType.THINGS:
                return this.advancedSearchBody;
            case SearchTargetType.LOCATIONS:
                return this.getThingSelectionInputBodyFromLocationAdvancedSearch();
            case SearchTargetType.CUSTOMERS:
                return this.getThingSelectionInputBodyFromCustomerAdvancedSearch();
            case SearchTargetType.PARTNERS:
            case SearchTargetType.ACTIONS:
            case SearchTargetType.ACTIVE_ALERTS:
            case SearchTargetType.HISTORICAL_ALERTS:
            case SearchTargetType.ACTIVE_EVENTS:
            case SearchTargetType.HISTORICAL_EVENTS:
            case SearchTargetType.ACTIVE_WORK_SESSIONS:
            case SearchTargetType.HISTORICAL_WORK_SESSIONS:
            case SearchTargetType.THING_CONNECTION_TOKENS:
                return null;
        }
    }

    private getThingSelectionInputBodyFromCustomerAdvancedSearch(): any {
        let result = {};
        if (this.advancedSearchBody) {
            if (this.advancedSearchBody.countries && this.advancedSearchBody.countries.length) {
                result['customer.countries'] = this.advancedSearchBody.countries;
            }
            if (this.advancedSearchBody.code) {
                result['customer.code'] = this.advancedSearchBody.code;
            }
            if (this.advancedSearchBody.name) {
                result['customer.name'] = this.advancedSearchBody.name;
            }
            const properties = Object.keys(result).filter(key => key.includes('properties.'));
            properties.forEach(property => {
                result['customer.' + property] = this.advancedSearchBody.property;
            });
        }
        return result;
    }

    private getThingSelectionInputBodyFromLocationAdvancedSearch(): any {
        let result = {};
        if (this.advancedSearchBody) {
            if (this.advancedSearchBody.customer) {
                result['customer'] = this.advancedSearchBody.customer;
            }
        }
        return result;
    }
}