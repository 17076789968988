import { Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild, forwardRef } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { firstValueFrom } from "rxjs";
import { CustomPropertyDefinition } from "../../model";
import { AuthenticationService } from "../../service/authentication.service";
import { ButtonActionValue, CustomTableColumn, CustomTableService } from "../custom-table";
import { ObjectArrayDialogComponent } from "./object-array-dialog.component";
import { ObjectArrayService } from "./object-array.service";

@Component({
    selector: 'object-array-table',
    template: require('./object-array-table.component.html'),
    styles: [`
        .table-card {
            border: 1px solid #ced4da;
            border-radius: .25rem;
            padding: 16px;
            margin-bottom: 1rem;
        }

        .file-input{
            display: none;
        }
    `],
    providers: [ObjectArrayService]
})
export class ObjectArrayTableComponent implements OnInit, OnDestroy {

    @Input() propertyDefinition: CustomPropertyDefinition;

    @Input() set values(val: { [propertyName: string]: any }[]) {
        this._values = val;
        if (this.properties) {
            this.updateValues();
        }
    }

    @Input() form: FormGroup;

    @Input() set enabled(value: boolean) {
        this._enabled = value;
        if (this.properties) {
            this.updateValues();
        }
    }

    @ViewChild('fileUpload') fileUpload: ElementRef;

    properties: CustomPropertyDefinition[];
    control: FormControl;
    displayedColumns: CustomTableColumn[] = [];
    dataSource = new MatTableDataSource<{ [propertyName: string]: string }>([]);
    loaded: boolean;
    _enabled: boolean;
    _values: { [propertyName: string]: any }[];
    error: string;

    private readonly INDEX_COLUMN = "_index";
    private userTypeId: string;
    private errorTimeoutId: any;

    constructor(
        @Inject(forwardRef(() => ObjectArrayService)) private objectArrayService: ObjectArrayService,
        @Inject(forwardRef(() => MatDialog)) private dialog: MatDialog,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService
    ) { }

    ngOnInit(): void {
        this.control = new FormControl(this._values);
        this.form.setControl(this.propertyDefinition.name, this.control);
        this.userTypeId = this.authenticationService.getUser().userTypeId;
        this.objectArrayService.getAllTaskPropertyDefinitions(this.propertyDefinition.id).then(properties => {
            this.properties = properties;
            this.setDisplayedColumns();
            this.updateValues();
            this.loaded = true;
        });
    }

    ngOnDestroy(): void {
        this.clearErrorTimeout();
    }

    private setDisplayedColumns() {
        let cols = this.properties.map(p => CustomTableService.newSimpleColumn(p.name, p.label || p.name, p.name));
        cols.push(
            CustomTableService.newButtonColumn('clone', '', '', 'float-right', 'cloneButton')
                .withVisiblePath('enabled')
                .withMatIcon('add_to_photos')
                .withMatIconClass('material-symbols-outlined')
                .withStyle({ '_any': { 'font-size': '20px', 'width': '10px' } })
                .withStickyEndColumn(),
            CustomTableService.newButtonColumn('delete', '', '', 'float-right', 'deleteButton')
                .withVisiblePath('enabled')
                .withMatIcon('delete')
                .withMatIconClass('material-symbols-outlined')
                .withStyle({ '_any': { 'font-size': '20px', 'width': '10px', 'color': '#ff0000' } })
                .withStickyEndColumn()
        );
        this.displayedColumns = cols;
    }

    private filterVisiblePropertiesByUserType(propDefs: CustomPropertyDefinition[], userTypeId: string): CustomPropertyDefinition[] {
        return propDefs.filter(prop => !prop.userTypeFiltered || !prop.userTypeIds || !prop.userTypeIds.length || prop.userTypeIds.includes(userTypeId));
    }

    addItem(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.minWidth = '25%';
        dialogConfig.autoFocus = false;
        dialogConfig.data = { definitions: this.filterVisiblePropertiesByUserType(this.properties, this.userTypeId) };
        firstValueFrom(this.dialog.open(ObjectArrayDialogComponent, dialogConfig).afterClosed()).then(result => {
            if (result) {
                if (!this._values) {
                    this._values = [];
                }
                this._values.push(result);
                this.updateValues();
            }
        });
    }

    editItem(item: { [key: string]: any }): void {
        if (!this._enabled) {
            return;
        }
        const dialogConfig = new MatDialogConfig();
        dialogConfig.minWidth = '25%';
        dialogConfig.autoFocus = false;
        dialogConfig.data = { definitions: this.filterVisiblePropertiesByUserType(this.properties, this.userTypeId), values: item };
        firstValueFrom(this.dialog.open(ObjectArrayDialogComponent, dialogConfig).afterClosed()).then(result => {
            if (result) {
                const index = item[this.INDEX_COLUMN];
                this._values[index] = result;
                this.updateValues();
            }
        });
    }

    private enrichItems(items: { [propertyName: string]: any }[]) {
        items?.forEach((v, i) => {
            v['enabled'] = this._enabled;
            v[this.INDEX_COLUMN] = i;
        });
    }

    private updateValues(): void {
        if (!this._values) {
            this._values = [];
        }
        this.enrichItems(this._values);
        this.dataSource = new MatTableDataSource<{ [propertyName: string]: any }>(this._values);
        if (this.control) {
            const values = (this._values || []).map(({ [this.INDEX_COLUMN]: index, enabled, ...otherAttrs }) => otherAttrs)
            this.control.setValue(values);
        }
    }

    onButtonAction(actionValue: ButtonActionValue): void {
        switch (actionValue.action) {
            case 'delete':
                this.deleteItem(actionValue.index);
                break;
            case 'clone':
                this.cloneItem(actionValue.index);
                break;
        }
    }

    private deleteItem(index: number): void {
        this._values.splice(index, 1);
        this.updateValues();
    }

    private cloneItem(index: number): void {
        var copy = Object.assign({}, this._values[index]);
        this._values.push(copy);
        this.updateValues();
    }

    importCsv(event): void {
        const filesList: FileList = <any>(event.srcElement || event.target).files;
        const file = filesList.item(0);
        this.fileUpload.nativeElement.value = '';
        if (file) {
            if (!file.type.startsWith("text")) {
                this.showImportError("File type not supported");
            } else {
                const reader = new FileReader();
                reader.onload = () => {
                    const propertyDefinitions: CustomPropertyDefinition[] = this.filterVisiblePropertiesByUserType(this.properties, this.userTypeId);
                    let fileContent: string = reader.result as string;
                    try {
                        const elements = this.objectArrayService.extractObjectArraysFromCsv(fileContent, propertyDefinitions);
                        this._values = elements;
                        this.updateValues();
                    } catch (err) {
                        this.showImportError((err as Error).message);
                    }
                };
                reader.onerror = () => {
                    this.showImportError("Error reading the file");
                };
                reader.readAsText(file);
            }
        }
    }

    private showImportError(errorMessage: string): void {
        this.clearErrorTimeout();
        this.error = errorMessage;
        this.errorTimeoutId = setTimeout(() => this.error = null, 5000);
    }

    private clearErrorTimeout(): void {
        if (this.errorTimeoutId) {
            clearTimeout(this.errorTimeoutId);
            this.errorTimeoutId = null;
        }
    }

}