import { AfterViewInit, Component, ContentChildren, ElementRef, forwardRef, Host, Inject, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewContainerRef } from '@angular/core';
import * as _ from 'lodash';
import { firstValueFrom, Subscription } from 'rxjs';
import { CUSTOM_FILTERS_AND_COMPONENTS_MAP } from '../../common/setup';
import { Alert, ConfigurationParameter, Customer, Location, Thing, ThingDataItem, Value, WorkSession } from '../../model';
import { AbstractExportContextService } from '../../service/abstract-export-context.service';
import { AuthenticationService } from '../../service/authentication.service';
import { DataService } from '../../service/data.service';
import { DownloadingObject, DownloadService } from '../../service/download.service';
import { FieldService } from '../../service/field.service';
import { ParameterService } from '../../service/parameter.service';
import { PropertyService } from '../../service/property.service';
import { AbstractContextService } from '../../shared/class/abstract-context-service.class';
import { AbstractThingContextService } from '../../shared/class/abstract-thing-context-service.class';
import { MetricDetailComponent, PropertyComponent, StatisticComponent } from '../../shared/component';
import { DownloadStatus, DownloadType } from '../../shared/download-dialog/download-dialog.component';
import { ConfigurationParameterEntryComponent } from '../configuration-parameters/configuration-parameter-entry.component';
import { WidgetWithLink } from '../shared/widget-with-link';

let nextId = 0;

@Component({
    selector: 'widget',
    template: require('./custom-widget.component.html')
})
export class CustomWidgetComponent extends WidgetWithLink implements OnInit, AfterViewInit, OnDestroy {

    @Input() name: string;

    @Input() title: string;

    @Input() config: object;

    @Input() inputs: { [id: string]: string };

    @Input() description: string;

    @Input() metricSubscriptionDisabled: boolean;

    @Input() lazyMetricDataLoading: boolean;

    @Input() collapsible: boolean;

    @ContentChildren(MetricDetailComponent) metrics: QueryList<MetricDetailComponent>;

    @ContentChildren(PropertyComponent) properties: QueryList<PropertyComponent>;

    @ContentChildren(ConfigurationParameterEntryComponent) configParams: QueryList<ConfigurationParameterEntryComponent>;

    @ContentChildren(StatisticComponent) statistics: QueryList<StatisticComponent>;

    @ViewChild('widgetBody') widgetBody: ElementRef;

    constructor(
        @Inject(forwardRef(() => AbstractThingContextService)) @Host() private thingContextService: AbstractThingContextService,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => PropertyService)) private propertyService: PropertyService,
        @Inject(forwardRef(() => FieldService)) private fieldService: FieldService,
        @Inject(forwardRef(() => ParameterService)) private parameterService: ParameterService,
        @Inject(forwardRef(() => AbstractExportContextService)) private exportService: AbstractExportContextService,
        @Inject(forwardRef(() => DataService)) private dataService: DataService,
        @Inject(forwardRef(() => DownloadService)) private downloadService: DownloadService,
        @Inject(forwardRef(() => ViewContainerRef)) private viewContainerRef: ViewContainerRef
    ) { super(); }

    private widget: any;
    private context: object = {};
    private currentData = {};
    private thing: Thing;
    private location: Location;
    private customer: Customer;
    private propertyNameIdMap: { [name: string]: string } = {};
    private inputValues: any;
    private initCompleted: boolean;
    private LOCATION_METRIC_PREFIX = 'location.';
    private alert: Alert;
    private workSession: WorkSession;
    private metricSubscriptions: Subscription[] = []
    private socketSubscriberId: string;
    private exportVisibilitySubscription: Subscription;
    private subscribedToExport: boolean;
    private fieldServiceSubscription: Subscription;
    private inputsFirstInitializationPromise: Promise<void>;

    ngOnInit(): void {
        this.widget = new CUSTOM_FILTERS_AND_COMPONENTS_MAP[this.name](this.config);
        if (this.inputs && Object.keys(this.inputs).length) {
            let distinctInputs = Array.from(new Set(Object.values(this.inputs)));
            this.inputsFirstInitializationPromise = new Promise(resolve => {
                this.fieldServiceSubscription = this.fieldService.subscribeToFields(distinctInputs).subscribe(values => {
                    if (values) {
                        // replace with mapped field
                        let inputValues = {};
                        for (let key of Object.keys(this.inputs)) {
                            let value = values[this.inputs[key]];
                            if (value !== undefined) { // don't add destroyed fields
                                inputValues[key] = value;
                            }
                        }
                        if (!_.isEqual(this.inputValues, inputValues) && Object.keys(inputValues).length) { // avoid to publish consecutively the same values
                            this.inputValues = _.cloneDeep(inputValues);
                            this.context['inputValues'] = this.inputValues;
                            this.onInputUpdated();
                        }
                        // inform first initialization that an input value has arrived 
                        if (!this.initCompleted) {
                            resolve();
                        }
                    }
                });
            });
        }
        this.socketSubscriberId = "custom-widget-" + nextId++;
    }

    private onInputUpdated(): void {
        if (this.widget?.onInputUpdated && this.initCompleted) {
            this.widget.onInputUpdated(this.context);
        }
    }

    ngAfterViewInit(): void {
        this.setContext();
        let initialDataRequests: Promise<void>[] = [];
        if (this.properties?.length) {
            this.properties.forEach(p => {
                this.propertyNameIdMap[p.name] = p.id || p.name;
                this.currentData[p.id || p.name] = _.get(this.thing || this.location || this.customer || {}, p.name);
                this.context['data'] = this.currentData;
            });
            if (this.thing && !this.metricSubscriptionDisabled) {
                this.propertyService.subscribeToThingProperties(this.thing.id).subscribe((thingEvent: ThingDataItem[]) => this.updateProperties(thingEvent));
            }
        }
        if (this.metrics?.length) {
            if (this.thing) {
                this.metrics.filter(m => !m.name.startsWith(this.LOCATION_METRIC_PREFIX)).forEach(m => {
                    const metricValueSubscription = m.getValueForDetail(this.thing, false);
                    if (!this.metricSubscriptionDisabled) {
                        const sub = metricValueSubscription.subscribe(v => {
                            this.currentData[m.id || m.name] = this.handleValue(v);
                            this.context['data'] = this.currentData;
                            this.onDataUpdated();
                        });
                        this.metricSubscriptions.push(sub);
                    }
                    initialDataRequests.push(firstValueFrom(metricValueSubscription).then(v => {
                        this.currentData[m.id || m.name] = this.handleValue(v);
                        this.context['data'] = this.currentData;
                    }));
                });
            }
            if (this.location) {
                this.metrics.filter(m => m.name.startsWith(this.LOCATION_METRIC_PREFIX)).forEach(m => {
                    let locationMetricName = m.name.substring(this.LOCATION_METRIC_PREFIX.length);
                    let value = this.location.metrics ? this.location.metrics[locationMetricName] : null;
                    this.currentData[m.id || locationMetricName] = value ? { value: value.value, ts: value.lastUpdateTimestamp } : null;
                    this.context['data'] = this.currentData;
                });
            }
        }
        if (this.configParams?.length && this.thing) {
            initialDataRequests.push(
                this.parameterService.getConfigurationParametersByThingDefinitionId(this.thing.thingDefinitionId).then(allParams => {
                    let configParamPromises: Promise<void>[] = [];
                    this.configParams.forEach(configParam => {
                        let param: ConfigurationParameter = allParams.find(p => p.name == configParam.name);
                        if (param?.metric) {
                            const configParamSubscirption = configParam.getValue(this.thing.id, param);
                            if (!this.metricSubscriptionDisabled) {
                                const sub = configParamSubscirption.subscribe(v => {
                                    this.currentData[configParam.name] = this.handleValue(v);
                                    this.context['data'] = this.currentData;
                                    this.onDataUpdated();
                                });
                                this.metricSubscriptions.push(sub);
                            }
                            configParamPromises.push(firstValueFrom(configParamSubscirption).then(v => {
                                this.currentData[configParam.name] = this.handleValue(v);
                                this.context['data'] = this.currentData;
                            }));
                        }
                    });
                    return Promise.all(configParamPromises).then(() => null);
                })
            );
        }
        if (this.inputs && Object.keys(this.inputs).length) {
            initialDataRequests.push(this.inputsFirstInitializationPromise);
        }
        if (this.widget?.onInit) {
            let waitForInitPromise: Promise<any>;
            if (this.lazyMetricDataLoading) { // resolving the initial data requests after the onInit
                waitForInitPromise = Promise.resolve(); // dummy promise
                Promise.all(initialDataRequests).then(() => { });
            } else { // waiting the initial data requests to proceed with the on init
                waitForInitPromise = Promise.all(initialDataRequests);
            }
            waitForInitPromise.then(() => {
                let promise = this.widget.onInit(this.context);
                if (promise) {
                    promise.then(() => this.initCompleted = true);
                } else {
                    this.initCompleted = true
                }
            });
        } else {
            this.initCompleted = true;
            Promise.all(initialDataRequests).then(() => null);
        }
        this.subscribeToExportVisibility();
    }

    private setContext(): void {
        // Add new property in template-loader.service.ts
        this.thing = this.thingContextService.getCurrentThing();
        this.location = this.contextService.getCurrentLocation();
        this.customer = this.contextService.getCurrentCustomer();
        this.alert = this.thingContextService.getCurrentAlert();
        this.workSession = this.thingContextService.getCurrentWorkSession();
        this.context['htmlElement'] = this.widgetBody.nativeElement;
        this.context['thing'] = this.thing;
        this.context['thingDefinition'] = this.thingContextService.getCurrentThingDefinition();
        this.context['location'] = this.location;
        this.context['customer'] = this.customer;
        this.context['partner'] = this.contextService.getCurrentPartner();
        this.context['user'] = this.authenticationService.getUser();
        this.context['data'] = this.currentData;
        this.context['inputValues'] = this.inputValues;
        this.context['metrics'] = this.getMetricsInfo();
        this.context['properties'] = this.getPropertiesInfo();
        this.context['configurationParameters'] = this.getConfigParamsInfos();
        this.context['statistics'] = this.getStatisticsInfos();
        this.context['alert'] = this.alert;
        this.context['workSession'] = this.workSession;
        this.context['subscribeTo'] = this.subscribeToMetric.bind(this);
        this.context['registerForExport'] = this.registerForExport.bind(this);
        this.context['notifyExport'] = this.notifyExport.bind(this);
        this.context['viewContainerRef'] = this.viewContainerRef;
    }

    private getMetricsInfo(): object[] {
        if (this.metrics) {
            return this.metrics.map(s => {
                return {
                    "id": s.id || s.name,
                    "name": s.name,
                    "label": s.label,
                    "filter": s.filter,
                    "x": s.x,
                    "y": s.y,
                    "chartOptions": s.chartOptions,
                    "icon": s.icon,
                    "unit": s.unit,
                    "aggregation": s.aggregation,
                    "config": s.config
                };
            });
        }
        return [];
    }

    private getPropertiesInfo(): object[] {
        if (this.properties) {
            return this.properties.map(s => {
                return {
                    "id": s.id || s.name,
                    "name": s.name,
                    "label": s.label,
                    "filter": s.filter,
                    "x": s.x,
                    "y": s.y,
                    "config": s.config
                };
            });
        }
        return [];
    }

    private getConfigParamsInfos(): object[] {
        if (this.configParams) {
            return this.configParams.map(s => {
                return {
                    "id": s.name,
                    "name": s.name,
                    "label": s.label,
                    "config": s.config
                };
            });
        }
        return [];
    }

    private getStatisticsInfos(): object[] {
        if (this.statistics) {
            return this.statistics.map(s => {
                return {
                    "id": s.name,
                    "name": s.name,
                    "label": s.label,
                    "limit": s.limit,
                    "thingDefinition": s.thingDefinition,
                    "sumMetric": s.sumMetric,
                    "groupBy": s.groupBy,
                    "query": s.query,
                    "aggregation": s.aggregation,
                    "property": s.property,
                    "filter": s.filter,
                    "description": s.description,
                    "config": s.config,
                    "resource": s.resource,
                    "activationType": s.activationType,
                    "sortDirection": s.sortDirection,
                    "averagedBy": s.averagedBy
                };
            });
        }
        return [];
    }

    private handleValue(v: Value): any {
        if (v) {
            let ts = v.timestamp;
            if (v.value) {
                if (Array.isArray(v.value) && v.value[0]) {
                    return { "value": v.value[0]['value'], "ts": ts };
                }
                return { "value": v.value.value, "ts": ts };
            }
        }
        return null;
    }

    private updateProperties(thingEvent: ThingDataItem[]): void {
        // getting the right property and publishing the value
        if (thingEvent && Object.keys(this.propertyNameIdMap).length) {
            for (let thingDataItem of thingEvent) {
                let name = PropertyService.getFieldName(thingDataItem);
                let notifyUpdate = Object.keys(this.propertyNameIdMap).indexOf(name) >= 0;
                if (notifyUpdate) {
                    this.currentData[this.propertyNameIdMap[name]] = thingDataItem.value;
                    this.context['data'] = this.currentData;
                    this.onDataUpdated();
                    break;
                }
            }
        }
    }

    private onDataUpdated(): void {
        if (this.widget?.onDataUpdated && this.initCompleted && !this.metricSubscriptionDisabled) {
            this.widget.onDataUpdated(this.context);
        }
    }

    ngOnDestroy(): void {
        if (this.inputs && Object.values(this.inputs).length) {
            this.fieldService.unsubscribeFromFields(Object.values(this.inputs));
        }
        if (this.widget?.onDestroy) {
            this.widget.onDestroy(this.context);
            this.widget = null; // destroy reference
        }
        if (this.metricSubscriptions?.length) {
            this.metricSubscriptions.forEach(s => s.unsubscribe());
            this.dataService.unsubscribeFromMetrics(this.socketSubscriberId);
        }
        if (this.exportVisibilitySubscription) {
            this.exportVisibilitySubscription.unsubscribe();
        }
        if (this.subscribedToExport) {
            this.unsubscribeFromExport();
        }
        if (this.fieldServiceSubscription) {
            this.fieldServiceSubscription.unsubscribe();
        }
    }

    private subscribeToExportVisibility(): void {
        this.exportVisibilitySubscription = this.exportService.getIsExportButtonPresent().subscribe(isExportButtonPresent => {
            this.context['pageExportButtonPresent'] = isExportButtonPresent;
            if (this.widget && this.widget.updateControls) {
                this.widget.updateControls(this.context);
            }
        });
    }

    private subscribeToMetric(metric: { name: string, thingId: string }, callback: Function): void {
        if (metric?.name && metric?.thingId && callback) {
            const sub = this.dataService.subscribeToMetric(metric.thingId, metric.name, this.socketSubscriberId).subscribe(data => {
                if (data) {
                    callback({ value: data.value, timestamp: data.timestamp });
                }
            });
            this.metricSubscriptions.push(sub);
        }
    }

    private registerForExport(title: string, callback: Function): void {
        if (title && callback) {
            if (this.subscribedToExport) {
                this.unsubscribeFromExport();
            }
            this.subscribedToExport = true;
            this.exportService.subscribeToExport(this.socketSubscriberId, title).subscribe(() => {
                callback(this.context);
            });
        }
    }

    private unsubscribeFromExport(): void {
        this.exportService.unsubscribeFromExport(this.socketSubscriberId);
    }

    private notifyExport(name: string, callback: Function): void {
        const downloadingObject: DownloadingObject = {
            fileName: name || this.title || 'custom',
            uuid: null,
            status: DownloadStatus.DOWNLOADING,
            type: DownloadType.CUSTOM,
            callback: callback
        }
        this.downloadService.addDownloadingObject(downloadingObject);
        this.downloadService.setVisible();
    }

}