import { Component, forwardRef, Inject, Input, QueryList, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from '../../../service/app.service';
import { FieldService } from '../../../service/field.service';
import { AlertAdvancedSearchAddPropertiesDialog } from '../../advanced-search/alert-advanced-search/alert-advanced-search-add-properties-dialog.component';
import { AlertAdvancedSearchComponent } from '../../advanced-search/alert-advanced-search/alert-advanced-search.component';
import { AbstractSearchFieldComponent } from '../abstract-search-field.component';
import { SearchFieldService } from '../search-field.service';
import { AlertsPropertySearchInputComponent } from './alerts-property-search-input.component';

@Component({
    selector: 'alerts-search-field',
    template: require('./alerts-search-field.component.html'),
    styles: [require('../search-field.component.css')],
})
export class AlertsSearchFieldComponent extends AbstractSearchFieldComponent {

    @Input() isHistorical: boolean;

    @Input() periodFilterEnabled: boolean = true;

    @ViewChild(AlertAdvancedSearchComponent) alertAdvancedSearch: AlertAdvancedSearchComponent;

    @ViewChild(AlertAdvancedSearchAddPropertiesDialog) addPropertiesDialog: AlertAdvancedSearchAddPropertiesDialog;

    @ViewChildren(AlertsPropertySearchInputComponent) private alertsPropertySearchInputs: QueryList<AlertsPropertySearchInputComponent>;

    constructor(
        @Inject(forwardRef(() => FieldService)) fieldService: FieldService,
        @Inject(forwardRef(() => SearchFieldService)) searchFieldService: SearchFieldService,
        @Inject(forwardRef(() => MatDialog)) matDialog: MatDialog,
        @Inject(forwardRef(() => ViewContainerRef)) vcRef: ViewContainerRef,
        @Inject(forwardRef(() => AppService)) appService: AppService
    ) {
        super(fieldService, searchFieldService, matDialog, vcRef, appService);
    }

    openAddPropertiesDialog(advancedSearchProperties: { name: string, label: string }[]): void {
        this.close();
        this.advancedSearchProperties = advancedSearchProperties;
        setTimeout(() => this.addPropertiesDialog.open(), 400);
    }

    closeAddPropertiesDialog(updatedSearchFields: string[]): void {
        if (updatedSearchFields) {
            this.alertAdvancedSearch.updateSearchFields(updatedSearchFields);
        }
        setTimeout(() => this.open(), 400);
    }

    performClear(): void {
        this.alertAdvancedSearch?.clearAll();
        this.clearInputs();
    }

    protected updateAdvancedSearch(fieldsToSaveBody: any): void {
        this.alertAdvancedSearch?.updateLocalStorage(fieldsToSaveBody);
        this.alertAdvancedSearch?.initAllConfigurations();
    }

    protected getEncodedRawValues(): void {
        let body: any = {};
        this.alertsPropertySearchInputs?.forEach(property => Object.assign(body, property.getFormValue(true)));
        return body;
    }

    protected getRawValues() {
        let body: any = {};
        this.alertsPropertySearchInputs?.forEach(property => Object.assign(body, property.getFormValue(false)));
        return body;
    }

    protected refreshInputConfigurations(): void {
        this.alertsPropertySearchInputs.forEach(input => {
            input.refreshConfiguration();
        });
    }

}