import { Component, forwardRef, Inject, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { DateAdapter } from "@angular/material/core";
import { DateRange, DefaultMatCalendarRangeStrategy, MAT_DATE_RANGE_SELECTION_STRATEGY } from "@angular/material/datepicker";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import * as moment from "moment";
import { Moment } from "moment";
import * as moment_tz from 'moment-timezone';
import { LOCALE_TIMEZONE } from "../../../common/config";
import { AuthenticationService } from "../../../service/authentication.service";
import { CUSTOM_RANGE, DateRangeGroup, DateRangeName, DateRangeService, RANGE_MAPPING } from "../../../service/date-range.service";
import { PeriodFieldSelectionStyle } from "./period-field-v2.component";

@Component({
    selector: 'period-field-selector-dialog',
    template: require('./period-field-selector-dialog.component.html'),
    styles: [require('./period-field-selector-dialog.component.css')],
    providers: [{
        provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
        useClass: DefaultMatCalendarRangeStrategy,
    }]
})
export class PeriodFieldSelectorDialogComponent {

    @ViewChild('form') form: NgForm;

    ranges: { [group: number]: DateRangeName[] } = {};
    sortedGroups: DateRangeGroup[] = [DateRangeGroup.HOURLY, DateRangeGroup.DAILY, DateRangeGroup.WEEKLY, DateRangeGroup.MONTHLY, DateRangeGroup.YEARLY];
    CUSTOM_RANGE_BUTTON: string = 'CUSTOM_RANGE';
    selectedGroup: string = this.CUSTOM_RANGE_BUTTON;
    selectedDateRangeName: DateRangeName;
    isMobile: boolean;
    selectedDateRange: DateRange<Moment>;
    startTime: string;
    endTime: string;
    today: Moment;
    showCustomRange: boolean;
    periodSelectionStyle: PeriodFieldSelectionStyle;
    futureDatesSelectable: boolean;
    minDate: any;
    showOnlyCustom: boolean;

    private timezone: string;

    constructor(
        @Inject(forwardRef(() => DateRangeService)) private dateRangeService: DateRangeService,
        @Inject(forwardRef(() => MatDialogRef)) public dialogRef: MatDialogRef<PeriodFieldSelectorDialogComponent>,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => DateAdapter)) private dateAdapter: DateAdapter<Moment>,
        @Inject(MAT_DIALOG_DATA) data
    ) {
        this.dateAdapter.setLocale(data.language);
        this.isMobile = data.isMobile;
        this.selectedDateRange = data.currentDateRange;
        this.selectedDateRangeName = data.currentDateRangeName;
        const filteredPeriods = data.periods.filter(p => p != CUSTOM_RANGE);
        for (let period of filteredPeriods) {
            let group = RANGE_MAPPING.get(period);
            if (!this.ranges[group]) {
                this.ranges[group] = [];
            }
            this.ranges[group].push(period);
            if (period == data.currentDateRangeName) {
                this.selectedGroup = group;
            }
        }
        this.sortedGroups = this.sortedGroups.filter(s => this.ranges[s]);
        if (this.selectedDateRange) {
            this.computeTimes();
        }
        this.showCustomRange = data.periods.includes(CUSTOM_RANGE);
        this.periodSelectionStyle = data.periodSelectionStyle;
        this.futureDatesSelectable = data.futureDatesSelectable;
        this.minDate = data.maxDaysBack > 0 ? moment().subtract(data.maxDaysBack, 'days') : null;
        if (!this.selectedDateRange && !this.selectedDateRangeName && filteredPeriods.length) {
            this.selectedGroup = RANGE_MAPPING.get(filteredPeriods[0])
        }
        this.showOnlyCustom = this.showCustomRange && !filteredPeriods.length;
        this.timezone = this.authenticationService.getUser()?.timezone;
        this.today = moment_tz.tz(this.timezone || LOCALE_TIMEZONE).endOf('day');
    }

    private computeTimes(): void {
        this.startTime = this.getTimeString(this.selectedDateRange.start);
        this.endTime = this.getTimeString(this.selectedDateRange.end);
    }

    private formatString(num: number) {
        return num.toString().length == 1 ? '0' + num : num;
    }

    getGroupName(groupName: string): string {
        if (this.isMobile && this.periodSelectionStyle == PeriodFieldSelectionStyle.TOGGLE) {
            return groupName.substring(0, 1);
        }
        return groupName;
    }

    getRangeLabel(rangeName: DateRangeName): string {
        return this.dateRangeService.getCustomDateRangeByName(rangeName).label;
    }

    changeGroup(value: any): void {
        this.selectedGroup = value;
        if (this.selectedGroup != this.CUSTOM_RANGE_BUTTON) {
            this.selectedDateRangeName = this.ranges[this.selectedGroup][0];
            this.changeRange(this.selectedDateRangeName);
        } else {
            if (this.selectedDateRangeName) {
                this.selectedDateRange = this.dateRangeService.getCustomDateRangeByName(this.selectedDateRangeName).range;
                this.computeTimes();
            }
            this.selectedDateRangeName = null;
        }
    }

    changeRange(value: DateRangeName): void {
        this.selectedDateRangeName = value;
        this.selectedDateRange = null;
        if (this.periodSelectionStyle == PeriodFieldSelectionStyle.FLATTEN) {
            this.selectedGroup = null;
            this.apply();
        }
    }

    reset(): void {
        this.selectedDateRangeName = null;
        this.selectedDateRange = null;
        this.selectedGroup = null;
        this.startTime = null;
        this.form?.controls?.startTime?.setValue(this.startTime);
        this.endTime = null;
        this.form?.controls?.endTime?.setValue(this.endTime);
        this.apply();
    }

    apply(): void {
        this.dialogRef.close(this.getValue());
    }

    private getValue(): { currentDateRange: DateRange<Moment>, currentDateRangeName: DateRangeName, currentGroup: DateRangeGroup } {
        if (this.selectedGroup == this.CUSTOM_RANGE_BUTTON) {
            let value = this.form?.form.getRawValue();
            if (value) {
                const startDateRange = moment_tz.tz(this.selectedDateRange.start.format("YYYY-MM-DD"), 'YYYY-MM-DD', this.timezone || LOCALE_TIMEZONE);
                const endDateRange = moment_tz.tz(this.selectedDateRange.end.format("YYYY-MM-DD"), 'YYYY-MM-DD', this.timezone || LOCALE_TIMEZONE);
                let startHours = Number(value.startTime?.substring(0, 2));
                let startMinutes = Number(value.startTime?.substring(3, 5));
                let start = startDateRange.set({ hour: startHours, minute: startMinutes, second: 0, millisecond: 0 });
                let endHours = Number(value.endTime?.substring(0, 2));
                let endMinutes = Number(value.endTime?.substring(3, 5));
                let end = endDateRange.set({ hour: endHours, minute: endMinutes, second: 59, millisecond: 999 });
                return { currentDateRange: new DateRange(start, end), currentDateRangeName: null, currentGroup: null };
            }
            return null;
        } else {
            return { currentDateRange: null, currentDateRangeName: this.selectedDateRangeName, currentGroup: this.selectedGroup as DateRangeGroup };
        }
    }

    isInvalid(): boolean {
        return !this.selectedDateRangeName && (!this.selectedDateRange?.start || !this.selectedDateRange?.end || this.form?.invalid);
    }

    onSelectedDateRangeChange(date: Moment) {
        if (!this.selectedDateRange?.start || this.selectedDateRange.end) {
            this.selectedDateRange = new DateRange(date, null);
        } else {
            if (date > this.selectedDateRange?.start) {
                this.selectedDateRange = new DateRange(this.selectedDateRange.start, date);
            } else {
                this.selectedDateRange = new DateRange(date, this.selectedDateRange.start);
            }
        }
        this.form?.controls?.startTime?.setValue('00:00:00');
        if (this.futureDatesSelectable) {
            this.form?.controls?.endTime?.setValue('23:59:59');
        } else {
            this.form?.controls?.endTime?.setValue(this.getTimeString(moment()));
        }
    }

    private getTimeString(moment: Moment): string {
        return this.formatString(moment.hours()) + ':' + this.formatString(moment.minutes()) + ':' + this.formatString(moment.seconds());
    }
}