import { Inject, Injectable, forwardRef } from "@angular/core";
import * as _ from 'lodash';
import { firstValueFrom } from "rxjs";
import { DATA_EXPORT_CONFIGURATION } from "../common/endpoints";
import { DataExportConfigurationProperties, MaxMonthsConfiguration } from "../model";
import { CustomDateRange, DateRangeName } from "./date-range.service";
import { HttpService } from "./http.service";


@Injectable()
export class DataExportConfigurationService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService
    ) { }

    getConfiguration(): Promise<DataExportConfigurationProperties> {
        return firstValueFrom(this.httpService.get<DataExportConfigurationProperties>(DATA_EXPORT_CONFIGURATION)).then(configuration => {
            if (configuration?.maxMonthsConfiguration?.length) {
                configuration.maxMonthsConfiguration = this.sortConfiguration(configuration.maxMonthsConfiguration);
            }
            return configuration;
        });
    }

    private sortConfiguration(configuration: MaxMonthsConfiguration[]): MaxMonthsConfiguration[] {
        return configuration.sort((a, b) => {
            if (a.maxMetrics === b.maxMetrics) {
                return 0;
            }
            if (a.maxMetrics === null) {
                return 1;
            }
            if (b.maxMetrics === null) {
                return -1;
            }
            return a.maxMetrics < b.maxMetrics ? -1 : 1;
        });
    }

    updateVisibleRanges(maxMonths: number, allowedPeriods: CustomDateRange[], filterPeriods: string[]): string[] {
        const thisYearRange = _.cloneDeep(allowedPeriods.find(el => el.name == DateRangeName.THIS_YEAR));
        const includeThisYear = thisYearRange && (thisYearRange.range.end).subtract(maxMonths, 'months').valueOf() < thisYearRange.range.start.valueOf();
        if (maxMonths < 6) {
            return filterPeriods.filter(r => r != DateRangeName.LAST_12_MONTHS && r != DateRangeName.LAST_YEAR &&
                r != DateRangeName.LAST_FULL_12_MONTHS && r != DateRangeName.LAST_6_MONTHS && (includeThisYear ? true : r != DateRangeName.THIS_YEAR));
        } else if (maxMonths < 12) {
            return filterPeriods.filter(r => r != DateRangeName.LAST_12_MONTHS && r != DateRangeName.LAST_YEAR &&
                r != DateRangeName.LAST_FULL_12_MONTHS && (includeThisYear ? true : r != DateRangeName.THIS_YEAR));
        } else {
            return filterPeriods;
        }
    }
}