import { SelectionModel } from "@angular/cdk/collections";
import { Component, forwardRef, Inject, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { ThingInventoryManagementType } from "../../model";
import { AuthenticationService } from "../../service/authentication.service";
import { AbstractElementSelectorTableComponent } from "../shared/abstract-element-selector-table.component";
import { DataExportMetric } from "./data-export-widget-schedule-page.component";

@Component({
    selector: 'data-export-metric-selector-table',
    template: require('./data-export-metric-selector-table.component.html'),
    styles: [require('./data-export-metric-selector-table.component.css')]
})
export class DataExportMetricSelectorTableComponent extends AbstractElementSelectorTableComponent<DataExportMetric> implements OnInit {
    defaultSort: string;

    constructor(
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService
    ) {
        super();
    }

    ngOnInit(): void {
        if (this.authenticationService.getThingInventoryManagement() == ThingInventoryManagementType.BY_MODEL) {
            this.displayedColumns = ['select', 'productModelPartName', 'group', 'label'];
            this.defaultSort = 'productModelPartName';
        } else {
            this.defaultSort = 'group';
        }
        this.selection = new SelectionModel<any>(true, this.selectedElements?.length ? this.selectedElements : []);
        this.dataSource = new MatTableDataSource<DataExportMetric>(this.elements);
    }

    doSimpleSearch(body: any) {
        let key = body.key;
        if (key) {
            key = key.toLowerCase();
            const filtered = this.elements.filter(p => p['label']?.toLowerCase().includes(key) || p['group']?.toLowerCase().includes(key) || p['productModelPartName']?.toLowerCase().includes(key));
            this.dataSource = new MatTableDataSource<DataExportMetric>(filtered);
        } else {
            this.dataSource = new MatTableDataSource<DataExportMetric>(this.elements);
        }
    }

}