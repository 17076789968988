import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ProductModelSparePartDefinitionReference, StoreOrderItem, StoreOrderItemType } from "../../../model";

@Component({
    selector: 'spare-part-definition-catalog-card',
    template: require('./spare-part-definition-catalog-card.component.html'),
    styles: [require('./spare-part-definition-catalog-card.component.css')]
})
export class SparePartDefinitionCatalogCardComponent implements OnInit {

    @Input() productModelSparePartDefinitionReference: ProductModelSparePartDefinitionReference;

    @Input() addToCartEnabled: boolean;

    @Input() cartUpdating: boolean;

    @Input() showTechnicalSchemePositions: boolean;

    @Output() addToCart = new EventEmitter();

    @Output() cardClick = new EventEmitter();

    quantity: number;

    ngOnInit(): void {
        this.quantity = this.productModelSparePartDefinitionReference.quantity || 1;
    }

    goToSparePartDefinitionDetails(): void {
        this.cardClick.emit({ id: this.productModelSparePartDefinitionReference.sparePartDefinitionId, quantity: this.quantity });
    }

    isOverflow(el: HTMLElement): boolean {
        var isOverflowing = el.clientWidth < el.scrollWidth
            || el.clientHeight < el.scrollHeight;
        return isOverflowing;
    }

    addQuantity(): void {
        this.quantity++;
    }

    removeQuantity(): void {
        if (this.quantity > 1) {
            this.quantity--;
        }
    }

    addSparePartDefinitionToCart(): void {
        let newItem = new StoreOrderItem();
        newItem.itemId = this.productModelSparePartDefinitionReference.sparePartDefinitionId;
        newItem.type = StoreOrderItemType.SPARE_PART;
        newItem.quantity = this.quantity;
        this.addToCart.emit(newItem);
    }

}