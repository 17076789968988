import { HttpParams } from "@angular/common/http";
import { Component, EventEmitter, forwardRef, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { FormControl, NgForm } from "@angular/forms";
import { DateRange } from "@angular/material/datepicker";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatTableDataSource } from "@angular/material/table";
import * as moment from "moment";
import { Moment } from "moment";
import { Subscription, take } from "rxjs";
import { ErrorMessages } from "../../common/constants";
import { DataExportConfigurationProperties, DataExportTimestampFormat, Location, Metric, MetricCategory, PagedList, ProductModel, ProductModelPart, Thing, ThingDefinition, ThingInventoryManagementType } from "../../model";
import { AuthenticationService } from "../../service/authentication.service";
import { ContextService } from "../../service/context.service";
import { CustomLabelService } from "../../service/custom-label.service";
import { DataExportConfigurationService } from "../../service/data-export-configuration.service";
import { DateRangeName, DateRangeService } from "../../service/date-range.service";
import { MetricService } from "../../service/metric.service";
import { ProductModelPartService } from "../../service/product-model-part.service";
import { ProductModelService } from "../../service/product-model.service";
import { ThingDefinitionService } from "../../service/thing-definition.service";
import { UserLocationService } from "../../service/user-location.service";
import { UserThingService } from "../../service/user-thing.service";
import { AbstractContextService } from "../../shared/class/abstract-context-service.class";
import { AbstractThingContextService } from "../../shared/class/abstract-thing-context-service.class";
import { PreselectedRangeComponent } from "../../shared/component/daterange-picker/preselected-range.component";
import { ButtonActionValue, CustomTableColumn, CustomTableService } from "../../shared/custom-table";
import { LocalizationPipe } from "../../shared/pipe";
import { ErrorUtility } from "../../utility/error-utility";
import { DataExportWidgetAddMetricDataDialog } from "./data-export-widget-add-metric-data-dialog.component";
import { DataExportWidgetService } from "./data-export-widget.service";

@Component({
    selector: 'data-export-widget-schedule-page',
    template: require('./data-export-widget-schedule-page.component.html'),
    styles: [require('./data-export-widget-schedule-page.component.css')]
})
export class DataExportWidgetSchedulePageComponent extends PreselectedRangeComponent implements OnInit, OnDestroy {

    @Input() configuration: DataExportConfigurationProperties;

    @Output() cancelAction = new EventEmitter();

    @ViewChild('scheduleForm') scheduleForm: NgForm;

    exportData: DataExportDataElement[] = [];
    error: string;
    range: DateRange<Moment>;
    maxDaysBack: number;
    locations: Location[] = [];
    things: Thing[] = [];
    contextCustomerId: string;
    locationControl = new FormControl({ value: null, disabled: true });
    thingControl = new FormControl({ value: null, disabled: true });
    displayedColumns: CustomTableColumn[];
    dataSource = new MatTableDataSource<DataExportDataElement>([]);
    contextThingId: string;
    maximumPeriodMessage: string;
    visibleRanges: string[];
    hideDateRangePicker: boolean;
    timestampFormats: { value: DataExportTimestampFormat, label: string }[] = [];
    isThingSelected: boolean;
    isCustomerUser: boolean;
    isLocationUser: boolean;
    invalidPeriod: boolean;
    maxMetricNumberExceeded: boolean;
    validating: boolean;
    maxMetricNumberExceededMessage: string = "Too many metrics selected: export at most ${maxMetric} metrics";

    private contextLocationId: string;
    private locationSub: Subscription;
    private defaultFileName: string = "${thing.serialNumber}_${thing.name}";
    private maximumPeriodDefaultMessage: string = "Maximum period ${maxMonths} months";
    private invalidPeriodConfiguration: boolean;
    private timezone: string;
    private thingInventoryManagement: ThingInventoryManagementType;
    private highestSelectedMetricCount: number;

    constructor(
        @Inject(forwardRef(() => DataExportWidgetService)) private dataExportWidgetService: DataExportWidgetService,
        @Inject(forwardRef(() => DateRangeService)) protected dateRangeService: DateRangeService,
        @Inject(forwardRef(() => CustomLabelService)) private labelService: CustomLabelService,
        @Inject(forwardRef(() => MatSnackBar)) private snackBar: MatSnackBar,
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
        @Inject(forwardRef(() => AbstractThingContextService)) private thingContextService: AbstractThingContextService,
        @Inject(forwardRef(() => UserLocationService)) private userLocationService: UserLocationService,
        @Inject(forwardRef(() => UserThingService)) private userThingService: UserThingService,
        @Inject(forwardRef(() => MatDialog)) private dialog: MatDialog,
        @Inject(forwardRef(() => DataExportConfigurationService)) private dataExportConfigurationService: DataExportConfigurationService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => ThingDefinitionService)) private thingDefinitionService: ThingDefinitionService,
        @Inject(forwardRef(() => ProductModelService)) private productModelService: ProductModelService,
        @Inject(forwardRef(() => ProductModelPartService)) private productModelPartService: ProductModelPartService,
        @Inject(forwardRef(() => MetricService)) private metricService: MetricService
    ) {
        super(dateRangeService);
    }

    ngOnInit() {
        this.defaultPeriodValue = DateRangeName.THIS_MONTH;
        let value = this.getPeriod();
        this.range = new DateRange(value.range.start, value.range.end);
        this.thingInventoryManagement = this.authenticationService.getThingInventoryManagement();
        this.checkContext();
        this.updateTableColumns();
        this.filterPeriods = this.filterPeriods || [DateRangeName.TODAY, DateRangeName.YESTERDAY, DateRangeName.LAST_24_HOURS, DateRangeName.LAST_7_DAYS, DateRangeName.LAST_30_DAYS, DateRangeName.THIS_MONTH, DateRangeName.LAST_MONTH, DateRangeName.LAST_12_MONTHS, DateRangeName.THIS_YEAR, 'CUSTOM'];
        this.timezone = this.authenticationService.getUser()?.timezone;
        this.initTimestampFormats();
        if (this.authenticationService.isCustomerUser()) {
            this.isCustomerUser = true;
            this.initSubscriptions();
            this.loadLocationList(this.authenticationService.getUser().customerId);
        } else if (this.authenticationService.isLocationUser()) {
            this.isLocationUser = true;
            this.getThings(this.authenticationService.getUser().locationId);
        } else {
            this.initSubscriptions();
        }
        if (this.contextThingId || (!this.contextService.getCurrentCustomer() && !this.contextService.getCurrentLocation())) {
            this.validateAllMetricSelected();
        }
        this.maxMetricNumberExceededMessage = this.localizationPipe.transform(this.maxMetricNumberExceededMessage.replace('${maxMetric}', this.configuration?.maxMetrics.toString()));
    }

    private getDisplayedColumns(): CustomTableColumn[] {
        let displayedColumns: CustomTableColumn[] = [];
        if (!this.contextThingId && !this.isThingSelected) {
            if (this.thingInventoryManagement == ThingInventoryManagementType.BY_MODEL) {
                displayedColumns.push(CustomTableService.newSimpleColumn('productModelName', 'productModelProperty', 'productModelName'));
            } else {
                displayedColumns.push(CustomTableService.newSimpleColumn('thingDefinitionName', 'thingDefinitionProperty', 'thingDefinitionName'));
            }
        }
        displayedColumns.push(
            CustomTableService.newSimpleColumn('metrics', 'metricsTabItem', 'metricDisplayedNames').withStyle({ '_any': { 'white-space': 'pre-line', 'padding': '8px 5px' } }),
            CustomTableService.newButtonColumn('delete', '', 'id', 'float-right', 'deleteButton').withMatIcon('delete').withMatIconClass('material-symbols-outlined').withStyle({ '_any': { 'font-size': '20px', 'color': '#ff0000', 'width': '10px' } }).withStickyEndColumn()
        );
        return displayedColumns;
    }

    ngOnDestroy(): void {
        if (this.locationSub) {
            this.locationSub.unsubscribe();
        }
    }

    private checkContext(): void {
        if (this.thingContextService.getCurrentThing()) {
            const contextThing = this.thingContextService.getCurrentThing();
            this.contextThingId = contextThing.id;
            this.contextLocationId = contextThing.locationId;
            this.contextCustomerId = contextThing.customerId;
        } else if (this.contextService.getCurrentLocation()) {
            const contextLocation = this.contextService.getCurrentLocation();
            this.contextLocationId = contextLocation.id;
            this.contextCustomerId = ContextService.getCustomerFromLocation(contextLocation)?.id;
        } else if (this.contextService.getCurrentCustomer()) {
            this.contextCustomerId = this.contextService.getCurrentCustomer().id;
        }
    }

    private initTimestampFormats(): void {
        this.timestampFormats.push({ value: DataExportTimestampFormat.ISO_8601, label: 'ISO (UTC)' });
        if (this.timezone && this.timezone != 'UTC') {
            this.timestampFormats.push({ value: DataExportTimestampFormat.ISO_8601_OFFSET, label: 'ISO (' + this.timezone + ')' });
        }
        this.timestampFormats.push({ value: DataExportTimestampFormat.EPOCH_MILLIS, label: 'Milliseconds' });
    }

    private isExportAllMetricDataSelected(): boolean {
        const values = this.scheduleForm?.form.getRawValue();
        return values?.exportAllMetricData;
    }

    loadLocationList(customerId: string): void {
        this.locations = [];
        this.locationControl.reset();
        if (customerId) {
            this.userLocationService.getRecursivelyAllLocations(null, [], customerId).then(locations => {
                this.locations = locations;
                if (this.contextLocationId) {
                    this.locationControl.setValue(this.contextLocationId);
                    this.locationControl.disable();
                } else {
                    this.locationControl.enable();
                }
            });
        } else {
            this.locationControl.disable();
        }
    }

    private initSubscriptions(): void {
        this.locationSub = this.locationControl.valueChanges.subscribe(locationId => {
            this.things = [];
            this.thingControl.reset();
            if (locationId) {
                this.getThings(locationId);
            } else {
                this.thingControl.disable();
            }
            this.isThingSelected = false;
            this.resetExportData();
            this.updateTableColumns();
            if (this.isExportAllMetricDataSelected()) {
                this.validateAllMetricSelected();
            }
        });
    }

    private getThings(locationId: string): void {
        let params: HttpParams = new HttpParams();
        if (this.thingInventoryManagement == ThingInventoryManagementType.BY_MODEL) {
            params = params.set('rootsOnly', true);
        }
        this.userThingService.getRecursivelyAllThings(null, [], locationId, null, null, params).then(things => {
            this.things = things;
            this.thingControl.enable();
        });
    }

    selectedThingChanged(thingId: string): void {
        this.isThingSelected = !!thingId;
        this.resetExportData();
        this.updateTableColumns();
        if (this.isExportAllMetricDataSelected()) {
            this.validateAllMetricSelected();
        }
    }

    selectPeriod(event: { range: DateRange<moment.Moment>, rangeName: string }) {
        this.range = event.range;
        this.defaultPeriodValue = DateRangeName[event.rangeName];
    }

    onCancel() {
        this.cancelAction.emit();
    }

    scheduleBulkDataExport(): void {
        const formValues = this.scheduleForm.form.getRawValue();
        if (formValues.exportAllMetricData) {
            this.getExportAllQualifiedMetricIds().then(qualifiedMetricIds => {
                const body = this.getBody(formValues, qualifiedMetricIds);
                this.dataExportWidgetService.scheduleBulkDataExport(body).then(() => {
                    this.error = null;
                    this.showSnackbar("dataExportPresetScheduledProperty");
                    this.onCancel();
                }).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.SAVE_DATA_ERROR));
            }).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.SAVE_DATA_ERROR));
        } else {
            let qualifiedMetricIds: QualifiedMetricId[] = [];
            this.exportData.forEach(data => {
                if (this.thingInventoryManagement == ThingInventoryManagementType.BY_MODEL) {
                    data.dataExportMetrics.forEach(m => {
                        if (m.productModelPartId) {
                            qualifiedMetricIds.push({ thingDefinitionId: null, productModelId: null, productModelPartId: m.productModelPartId, metricId: m.id });
                        } else {
                            qualifiedMetricIds.push({ thingDefinitionId: null, productModelId: data.productModelId, productModelPartId: null, metricId: m.id });
                        }
                    });
                } else {
                    data.dataExportMetrics.forEach(m => {
                        qualifiedMetricIds.push({ thingDefinitionId: data.thingDefinitionId, productModelId: null, productModelPartId: null, metricId: m.id });
                    });
                }
            });
            const body = this.getBody(formValues, qualifiedMetricIds);
            this.dataExportWidgetService.scheduleBulkDataExport(body).then(() => {
                this.error = null;
                this.showSnackbar("dataExportPresetScheduledProperty");
                this.onCancel();
            }).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.SAVE_DATA_ERROR));
        }
    }

    private getBody(formValues: any, qualifiedMetricIds: QualifiedMetricId[]): any {
        let body = {
            name: formValues.name,
            thingFileName: this.defaultFileName,
            hashThingFileName: formValues.hashThingFileName ? formValues.hashThingFileName : null,
            qualifiedMetricIds: qualifiedMetricIds,
            startTimestamp: this.range.start.valueOf(),
            endTimestamp: this.range.end.valueOf(),
            timestampFormat: formValues.timestampFormat,
            includeUnit: formValues.includeUnit ? formValues.includeUnit : null
        }
        if (this.contextThingId) {
            body['thingId'] = this.contextThingId;
            body['locationId'] = this.contextLocationId;
            body['customerId'] = this.contextCustomerId;
        } else {
            if (this.thingControl.value) {
                body['thingId'] = this.thingControl.value;
            }
            if (this.locationControl.value) {
                body['locationId'] = this.locationControl.value;
            }
            if (formValues.customer) {
                body['customerId'] = formValues.customer;
            }
        }
        if (this.contextService.getCurrentPartner()) {
            body['partnerId'] = this.contextService.getCurrentPartner().id;
        }
        if (body['timestampFormat'] == DataExportTimestampFormat.ISO_8601_OFFSET) {
            body['timezone'] = this.timezone;
        }
        return body;
    }

    private showSnackbar(text: string): void {
        this.labelService.getCustomLabel(text)
            .then(message => {
                this.snackBar.open(this.localizationPipe.transform(message), '', {
                    duration: 4000,
                    panelClass: 'notification-info'
                });
            });
    }

    isFormValid(): boolean {
        if (this.invalidPeriodConfiguration || this.validating || this.maxMetricNumberExceeded) {
            return false;
        }
        if (this.scheduleForm) {
            const formValues = this.scheduleForm.form.getRawValue();
            return this.highestSelectedMetricCount > 0 && this.isPeriodValid() && formValues.name;
        } else {
            return false;
        }
    }

    openAddMetricDataDialog(index: number): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = "data-export-add-metric-data-dialog";
        const selectedElement = (index != null) ? this.exportData[index] : null;
        let excludedIds = [];
        if (this.thingInventoryManagement == ThingInventoryManagementType.BY_MODEL) {
            excludedIds = selectedElement ?
                (this.exportData.filter(data => data.productModelId != selectedElement.productModelId).map(data => { return data.productModelId })) :
                (this.exportData.map(data => { return data.productModelId }));
        } else {
            excludedIds = selectedElement ?
                (this.exportData.filter(data => data.thingDefinitionId != selectedElement.thingDefinitionId).map(data => { return data.thingDefinitionId })) :
                (this.exportData.map(data => { return data.thingDefinitionId }));
        }
        const selectedThing = this.thingControl.value ? this.things.find(t => t.id == this.thingControl.value) : null;
        dialogConfig.data = {
            selectedElement: selectedElement,
            params: this.getDialogParams(),
            currentThing: this.thingContextService.getCurrentThing() || selectedThing,
            excludedIds: excludedIds
        }
        dialogConfig.autoFocus = false;
        dialogConfig.width = '640px';
        dialogConfig.disableClose = true;
        this.dialog.open(DataExportWidgetAddMetricDataDialog, dialogConfig).afterClosed().pipe(take(1)).subscribe(result => {
            if (result) {
                if (index != null) {
                    this.exportData[index] = result;
                    this.dataSource = new MatTableDataSource<DataExportDataElement>(this.exportData);
                } else {
                    this.exportData.push(result);
                    this.dataSource = new MatTableDataSource<DataExportDataElement>(this.exportData);
                }
                this.validateSelectedMetricLimits();
            }
        });
    }

    execButtonAction(actionValue: ButtonActionValue): void {
        switch (actionValue.action) {
            case 'delete':
                this.exportData.splice(actionValue.index, 1);
                this.dataSource = new MatTableDataSource<DataExportDataElement>(this.exportData);
                this.validateSelectedMetricLimits();
                break;
        }
    }

    private validateSelectedMetricLimits(): void {
        this.highestSelectedMetricCount = this.exportData?.length ? this.exportData.reduce((a, b) => (a.highestSelectedMetricCount > b.highestSelectedMetricCount ? a : b)).highestSelectedMetricCount : 0;
        this.maxMetricNumberExceeded = this.configuration?.maxMetrics != -1 && this.highestSelectedMetricCount > this.configuration.maxMetrics;
        this.updateDateLimits();
    }

    private updateDateLimits(): void {
        const configurationProperty = this.configuration?.maxMonthsConfiguration?.find(conf => this.highestSelectedMetricCount <= conf.maxMetrics || conf.maxMetrics == null);
        const monthLimit = configurationProperty?.maxMonths != null ? configurationProperty.maxMonths : 0;
        if (monthLimit == 0) {
            this.error = "Invalid maxPeriod configuration";
            this.invalidPeriodConfiguration = true;
            return;
        }
        const momentLimit = moment().subtract(monthLimit, 'months');
        const daysLimit = moment().diff(momentLimit, 'days');
        const maxPeriodChanged = daysLimit != this.maxDaysBack;
        this.maxDaysBack = daysLimit;
        this.maximumPeriodMessage = this.localizationPipe.transform(this.maximumPeriodDefaultMessage).replace('${maxMonths}', monthLimit.toString());
        if (maxPeriodChanged) {
            this.hideDateRangePicker = true;
            this.visibleRanges = this.dataExportConfigurationService.updateVisibleRanges(monthLimit, this.allowedPeriods, this.filterPeriods);
            setTimeout(() => this.hideDateRangePicker = false, 10);
        }
    }

    private getDialogParams(): HttpParams {
        let params = new HttpParams();
        const formValues = this.scheduleForm?.form.getRawValue();
        if (formValues?.customer) {
            params = params.set('customerId', formValues.customer);
        }
        if (this.locationControl.value) {
            params = params.set('locationId', this.locationControl.value);
        }
        if (this.thingControl.value) {
            params = params.set('selectedThingId', this.thingControl.value);
        }
        if (this.contextService.getCurrentPartner()) {
            params = params.set('partnerId', this.contextService.getCurrentPartner().id);
        }
        return params;
    }

    private resetExportData(): void {
        this.exportData = [];
        this.dataSource = new MatTableDataSource<DataExportDataElement>(this.exportData);
    }

    private updateTableColumns(): void {
        this.displayedColumns = this.getDisplayedColumns();
    }

    private isPeriodValid(): boolean {
        if (!this.range || !this.range.start || !this.range.end || this.invalidPeriodConfiguration) {
            return false;
        }
        return !this.isPeriodLimitExceeded();
    }

    isPeriodLimitExceeded(): boolean {
        if (!this.range || !this.range.start || !this.range.end) {
            return false;
        }
        const start: number = this.range.start.valueOf();
        const end: number = this.range.end.valueOf();
        const limit = this.maxDaysBack * 24 * 60 * 60 * 1000;
        return (end - start) > limit;
    }

    exportAllMetricDataToggle(event: MatSlideToggleChange): void {
        this.resetExportData();
        if (event.checked) {
            this.validateAllMetricSelected();
        } else {
            this.validateSelectedMetricLimits();
        }
    }

    private validateAllMetricSelected(): void {
        this.validating = true;
        let params = this.getDialogParams();
        if (this.contextCustomerId) {
            params = params.set('customerId', this.contextCustomerId);
        }
        if (this.contextLocationId) {
            params = params.set('locationId', this.contextLocationId);
        }
        if (this.contextThingId) {
            params = params.set('selectedThingId', this.contextThingId);
        }
        if (this.thingInventoryManagement == ThingInventoryManagementType.BY_MODEL) {
            let promises = [];
            promises.push(this.productModelService.getProductModelsAssociatedToThings(params));
            promises.push(this.productModelPartService.getRecursivelyAllProductModelParts());
            Promise.all(promises).then(results => {
                let productModels: ProductModel[] = results[0];
                const productModelIds = productModels.map(pm => { return pm.id });
                let productModelParts: ProductModelPart[] = results[1].filter((part: ProductModelPart) => part.thingDefinitionId && productModelIds.includes(part.productModelId));
                let metricPromises = [];
                productModels.forEach(pm => {
                    metricPromises.push(this.metricService.getPagedMetricsByThingDefinitionId(pm.thingDefinitionId, true, MetricCategory.ALL, null, 0, 1, null));
                });
                productModelParts.forEach(part => {
                    metricPromises.push(this.metricService.getPagedMetricsByThingDefinitionId(part.thingDefinitionId, true, MetricCategory.ALL, null, 0, 1, null));
                });
                Promise.all(metricPromises).then((results: PagedList<Metric>[]) => {
                    const maxTotalElements: number = results?.length ? results.reduce((a, b) => (a.totalElements > b.totalElements ? a : b)).totalElements : 0;
                    this.validating = false;
                    this.highestSelectedMetricCount = maxTotalElements;
                    this.maxMetricNumberExceeded = this.configuration?.maxMetrics != -1 && maxTotalElements > this.configuration.maxMetrics;
                    this.updateDateLimits();
                }).catch(err => this.handleValidatingError(err));
            }).catch(err => this.handleValidatingError(err));
        } else {
            this.thingDefinitionService.getThingDefinitionsAssociatedToThings(params).then(thingDefs => {
                let promises = [];
                thingDefs.forEach(thingDef => {
                    promises.push(this.metricService.getPagedMetricsByThingDefinitionId(thingDef.id, true, MetricCategory.ALL, null, 0, 1, null));
                });
                Promise.all(promises).then((results: PagedList<ThingDefinition>[]) => {
                    const maxTotalElements: number = results?.length ? results.reduce((a, b) => (a.totalElements > b.totalElements ? a : b)).totalElements : 0;
                    this.validating = false;
                    this.highestSelectedMetricCount = maxTotalElements;
                    this.maxMetricNumberExceeded = this.configuration?.maxMetrics != -1 && maxTotalElements > this.configuration.maxMetrics;
                    this.updateDateLimits();
                }).catch(err => this.handleValidatingError(err));
            }).catch(err => this.handleValidatingError(err));
        }
    }

    private handleValidatingError(err: any): void {
        this.validating = false;
        this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR);
    }

    private getExportAllQualifiedMetricIds(): Promise<QualifiedMetricId[]> {
        let params = this.getDialogParams();
        if (this.contextCustomerId) {
            params = params.set('customerId', this.contextCustomerId);
        }
        if (this.contextLocationId) {
            params = params.set('locationId', this.contextLocationId);
        }
        if (this.contextThingId) {
            params = params.set('selectedThingId', this.contextThingId);
        }
        if (this.thingInventoryManagement == ThingInventoryManagementType.BY_MODEL) {
            let promises = [];
            promises.push(this.productModelService.getProductModelsAssociatedToThings(params));
            promises.push(this.productModelPartService.getRecursivelyAllProductModelParts());
            return Promise.all(promises).then(results => {
                let productModels: ProductModel[] = results[0];
                const productModelIds = productModels.map(pm => { return pm.id });
                let productModelParts: ProductModelPart[] = results[1].filter((part: ProductModelPart) => part.thingDefinitionId && productModelIds.includes(part.productModelId));
                let metricPromises = [];
                productModels.forEach(pm => {
                    metricPromises.push(this.metricService.getMetricsByThingDefinitionId(pm.thingDefinitionId).then(metrics => {
                        return metrics.map(m => { return { thingDefinitionId: null, productModelId: pm.id, productModelPartId: null, metricId: m.id } });
                    }));
                });
                productModelParts.forEach(part => {
                    metricPromises.push(this.metricService.getMetricsByThingDefinitionId(part.thingDefinitionId).then(metrics => {
                        return metrics.map(m => { return { thingDefinitionId: null, productModelId: null, productModelPartId: part.id, metricId: m.id } });
                    }));
                });
                return Promise.all(metricPromises).then((results: QualifiedMetricId[][]) => {
                    return [].concat(...results);
                });
            });
        } else {
            return this.thingDefinitionService.getThingDefinitionsAssociatedToThings(params).then(thingDefinitions => {
                let promises = [];
                thingDefinitions.forEach(thingDef => {
                    promises.push(this.metricService.getMetricsByThingDefinitionId(thingDef.id).then(metrics => {
                        return metrics.map(m => { return { thingDefinitionId: thingDef.id, productModelId: null, productModelPartId: null, metricId: m.id } });
                    }));
                });
                return Promise.all(promises).then((results: QualifiedMetricId[][]) => {
                    return [].concat(...results);
                });
            });
        }
    }

}

export class DataExportDataElement {
    thingDefinitionName: string;
    thingDefinitionId: string;
    productModelName: string;
    productModelId: string;
    dataExportMetrics: DataExportMetric[];
    metricDisplayedNames: string;
    highestSelectedMetricCount: number;
}

export class DataExportMetric {
    id: string;
    name: string;
    label: string;
    group: string;
    productModelPartId: string;
    productModelPartName: string;
}

export class QualifiedMetricId {
    thingDefinitionId: string;
    productModelId: string;
    productModelPartId: string;
    metricId: string;
}