import * as L from 'leaflet';
import { AlertCategory, ThingAlertStatus } from '../../../model';
import { MapFilterRequiredData } from '../../../model/map-filter-required-data';
import { MapContext } from '../map.component';
import { LocationForMap, ThingForMap } from '../map.service';
import { MapFilter } from './map-filter';

const MARKER_SVG = `<svg width="26" height="40" viewBox="0 0 26 40" xmlns="http://www.w3.org/2000/svg">
<path d="M1 13C1.00036 19.9142 8.08249 32.7303 11.3652 38.2994C12.1115 39.5656 13.8885 39.5656 14.6348 38.2994C17.9175 32.7303 24.9995 19.9142 24.9996 13C24.9996 6.37258 19.6274 1 13 1C6.37258 1 0.999654 6.37258 1 13Z" />
</svg>`;

export class StatusMapFilter implements MapFilter {

    private icons: any[] = [];
    private config: any;
    private context: MapContext;
    private rankDescriptor = [
        { 'label': 'Normal', 'rank': 0, 'color': '#657895', 'clusterClass': 'cluster-normal', 'clusterSelectedClass': 'cluster-normal-selected' },
        { 'label': 'Anomaly', 'rank': 1, 'color': '#FFA726', 'clusterClass': 'cluster-anomaly', 'clusterSelectedClass': 'cluster-anomaly-selected' },
        { 'label': 'Failure', 'rank': 2, 'color': '#FB4E3D', 'clusterClass': 'cluster-failure', 'clusterSelectedClass': 'cluster-failure-selected' }
    ];

    constructor(config: any) {
        this.config = config || {};
        this.config.label = this.config.label || "Status";

        this.rankDescriptor.forEach((rd, i) => {
            this.icons.splice(i, 0,
                L.divIcon({
                    html: MARKER_SVG,
                    className: `marker-${rd.label.toLocaleLowerCase()}`,
                    iconSize: [26, 40],
                    iconAnchor: [13, 40],
                })
            );
            this.icons.push(
                L.divIcon({
                    html: MARKER_SVG,
                    className: `marker-${rd.label.toLocaleLowerCase()}-selected`,
                    iconSize: [26, 40],
                    iconAnchor: [13, 40],
                })
            );
        });
    }

    setContext(context: MapContext): void {
        this.context = context;
    }

    getRequiredData(): MapFilterRequiredData {
        const requireData = new MapFilterRequiredData();
        if (this.context == MapContext.THINGS) {
            requireData.things = true;
            requireData.locations = false;
            requireData.alerts = true;
        } else {
            requireData.things = false;
            requireData.locations = true;
            requireData.alerts = true;
        }
        return requireData;
    }

    getConfig(): any {
        return this.config;
    }

    computeRank(elements: ThingForMap[] | LocationForMap[], data: any): void {
        let alertMap: { [key: string]: number; };
        if (this.context == MapContext.THINGS) {
            alertMap = this.getThingAlerts(data.alerts);
        } else {
            alertMap = this.getLocationAlerts(data.alerts);
        }
        elements.forEach(el => {
            el.rank = alertMap[el.id] || 0;
        });
    }

    updateMarker(element: ThingForMap | LocationForMap, marker: any): void {
        if (element.selected) {
            marker.setIcon(this.icons[this.rankDescriptor.length + element.rank]);
        } else {
            marker.setIcon(this.icons[element.rank]);
        }
    }

    getRankDescriptors(): any[] {
        return this.rankDescriptor;
    }

    private getThingAlerts(alerts: ThingAlertStatus[]): { [thingId: string]: number } {
        const thingAlertStatusMap: { [thingId: string]: number } = {};
        alerts.forEach(a => {
            if (a.thingId) {
                const currentStatus = thingAlertStatusMap[a.thingId];
                if (!currentStatus || currentStatus == 0) {
                    thingAlertStatusMap[a.thingId] = this.getAlertRankFromCategory(a.category);
                } else if (currentStatus == 1) {
                    const newStatus = this.getAlertRankFromCategory(a.category);
                    if (newStatus == 2) {
                        thingAlertStatusMap[a.thingId] = newStatus;
                    }
                }
            }
        });
        return thingAlertStatusMap;
    }

    private getLocationAlerts(alerts: ThingAlertStatus[]): { [locationId: string]: number } {
        const locationAlertStatusMap: { [locationId: string]: number } = {};
        alerts.forEach(a => {
            const currentStatus = locationAlertStatusMap[a.locationId];
            if (!currentStatus || currentStatus == 0) {
                locationAlertStatusMap[a.locationId] = this.getAlertRankFromCategory(a.category);
            } else if (currentStatus == 1) {
                const newStatus = this.getAlertRankFromCategory(a.category);
                if (newStatus == 2) {
                    locationAlertStatusMap[a.locationId] = newStatus;
                }
            }
        });
        return locationAlertStatusMap;
    }

    private getAlertRankFromCategory(category: AlertCategory): number {
        switch (category) {
            case AlertCategory.FAILURE:
                return 2;
            case AlertCategory.ANOMALY:
                return 1;
            default:
                return 0;
        }
    }
}