import { HttpParams } from "@angular/common/http";
import { forwardRef, Inject, Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { TEMPLATE_CONTENT, USER_DASHBOARDS_V2, USER_TEMPLATES_V2, USER_UI_PROFILE_PAGES_V2, USER_UI_PROFILES_V2 } from "../common/endpoints";
import { Dashboard, PagedList, Template, UiProfile, UiProfilePage, UiProfilePageNavigationType } from "../model";
import { HttpService } from "./http.service";

@Injectable()
export class UiService {

    private profile: UiProfile;
    private pages: UiProfilePage[] = [];
    private loaded: Promise<void>;
    private templateContentCache: { [name: string]: string } = {};

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService
    ) { }

    load(): void {
        this.loaded = new Promise(resolve => {
            let params = new HttpParams().set('page', '0').set('size', '1');
            firstValueFrom(this.httpService.get<PagedList<UiProfile>>(USER_UI_PROFILES_V2, params)).then(profiles => {
                if (profiles.content.length == 0) {
                    console.error('No profile configured for current User');
                    resolve();
                } else {
                    this.profile = profiles.content[0];
                    let httpParams = new HttpParams().set('uiProfileId', this.profile.id).set('page', '0').set('size', '50');
                    firstValueFrom(this.httpService.get<PagedList<UiProfilePage>>(USER_UI_PROFILE_PAGES_V2, httpParams)).then(pages => {
                        let unsortedPages = pages.content;
                        this.pages = (this.profile.sidebarPageIds || []).filter(pageId => unsortedPages.some(p => p.id == pageId))
                            .map(pageId => unsortedPages.find(p => p.id == pageId));
                        resolve();
                    });
                }
            });
        });
    }

    waitForLoaded(): Promise<void> {
        return this.loaded;
    }

    getUserPagedDashboards(thingId: string, page: number, size: number): Promise<PagedList<Dashboard>> {
        let params = new HttpParams().set('thingId', thingId).set('page', page + '').set('size', size + '');
        return firstValueFrom(this.httpService.get<PagedList<Dashboard>>(USER_DASHBOARDS_V2, params));
    }

    getUserPagedTemplates(name: string, page: number, size: number, thingDefinitionId?: string): Promise<PagedList<Template>> {
        let params = new HttpParams().set('name', name).set('page', page + '').set('size', size + '');
        if (thingDefinitionId) {
            params = params.set('thingDefinitionId', thingDefinitionId);
        }
        return firstValueFrom(this.httpService.get<PagedList<Template>>(USER_TEMPLATES_V2, params));
    }

    getUiProfile(): UiProfile {
        return this.profile;
    }

    getUiProfilePages(): UiProfilePage[] {
        return this.pages;
    }

    getSidebarPages(): UiProfilePage[] {
        return this.getUiProfilePages().filter(p => p.navigation == UiProfilePageNavigationType.SIDEBAR_MENU);
    }

    getVisibleSidebarPages(): UiProfilePage[] {
        let pages = this.getSidebarPages();
        if (pages) {
            return pages.filter(page => {
                if (!page.visibilityCondition) {
                    return true;
                }
                let visibilityCondition = this.normalizeVisibilityCondition(page.visibilityCondition);
                try {
                    return eval(visibilityCondition);
                } catch {
                    return false;
                }
            });
        }
        return [];
    }

    normalizeVisibilityCondition(visibilityCondition: string): string {
        let appUtilsFunctions = ['getUser', 'isMobile', 'isTablet', 'getThing', 'getThingDefinition', 'getCustomer', 'getLocation', 'getPartner', 'getServiceLevel', 'getTestSession',
            'getTestSessionStartDate', 'getTestSessionStopDate', 'getUserCustomer', 'getCustomerServiceLevel', 'getWorkSession', 'getTags',
            'getCurrentTimestamp', 'getAlert', 'getTenant'];
        appUtilsFunctions.forEach(f => visibilityCondition = (visibilityCondition as any).replaceAll(f, 'appUtils.' + f));
        return visibilityCondition;
    }

    getTopbarLeft(): UiProfilePage {
        let topBar: UiProfilePage = this.getUiProfilePages().find(p => p.navigation == UiProfilePageNavigationType.TOPBAR_LEFT);
        if (topBar && this.isTopBarVisible(topBar)) {
            return topBar;
        }
        return null;
    }

    getTopbarRight(): UiProfilePage {
        let topBar: UiProfilePage = this.getUiProfilePages().find(p => p.navigation == UiProfilePageNavigationType.TOPBAR_RIGHT);
        if (topBar && this.isTopBarVisible(topBar)) {
            return topBar;
        }
        return null;
    }

    private isTopBarVisible(topBar: UiProfilePage): boolean {
        if (!topBar.visibilityCondition) {
            return true;
        }
        let visibilityCondition = this.normalizeVisibilityCondition(topBar.visibilityCondition);
        try {
            return eval(visibilityCondition);
        } catch {
            return false;
        }
    }

    getUiProfilePageByUrl(url: string): UiProfilePage {
        return this.pages.find(p => p.urlPath == url);
    }

    getContentByTemplateName(name: string, thingDefinitionId?: string): Promise<string> {
        let thingDefSuffix = thingDefinitionId ? '_' + thingDefinitionId : '';
        if (this.templateContentCache.hasOwnProperty(name + thingDefSuffix)) {
            return Promise.resolve(this.templateContentCache[name + thingDefSuffix]);
        }
        return this.getUserPagedTemplates(name, 0, 1, thingDefinitionId).then(templatePagedList => {
            if (templatePagedList.numberOfElements > 0) {
                let template = templatePagedList.content[0];
                return firstValueFrom(this.httpService.getText(TEMPLATE_CONTENT.replace('{id}', template.id))).then(content => {
                    this.templateContentCache[name + thingDefSuffix] = content;
                    return content;
                });
            } else {
                this.templateContentCache[name + thingDefSuffix] = '';
                return '';
            }
        });
    }

    getEventDetailsTemplate(): string {
        return this.profile.activeAlertDetailsTemplate;
    }

    getTopbarPages(): UiProfilePage[] {
        return this.getUiProfilePages().filter(p => p.navigation == UiProfilePageNavigationType.TOPBAR);
    }

    getUserMenuPages(): UiProfilePage[] {
        return this.getUiProfilePages().filter(p => p.navigation == UiProfilePageNavigationType.USER_MENU);
    }

}