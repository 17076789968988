import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Sort } from "@angular/material/sort";
import { ProductModelSparePartDefinitionReference, StoreOrderItem, StoreOrderItemType } from "../../../model";

@Component({
    selector: 'spare-part-definition-catalog-list',
    template: require('./spare-part-definition-catalog-list.component.html'),
    styles: [require('./spare-part-definition-catalog-list.component.css')]
})
export class SparePartDefinitionCatalogListComponent {

    @Input() set productModelSparePartDefinitionReferences(values: ProductModelSparePartDefinitionReference[]) {
        this._productModelSparePartDefinitionReferences = null;
        if (values?.length) {
            this.init(values);
        }
    };

    @Input() addToCartEnabled: boolean;

    @Input() cartUpdating: boolean;

    @Input() sort: any[] = ['', ''];

    @Input() showTechnicalSchemePositions: boolean;

    @Input() hidePrices: boolean;

    @Output() addToCart = new EventEmitter();

    @Output() rowClickAction = new EventEmitter();

    @Output() sortAction = new EventEmitter();

    quantities: { [productModelSparePartDefinitionReferenceId: string]: number } = {};
    _productModelSparePartDefinitionReferences: ProductModelSparePartDefinitionReference[];
    displayedColumns: string[];

    getDisplayedColumns(): string[] {
        let columns = [];
        if (this.showTechnicalSchemePositions) {
            columns.push('technicalSchemePosition');
        }
        columns = columns.concat(['code', 'name']);
        if (!this.hidePrices) {
            columns.push('price');
        }
        if (this.addToCartEnabled) {
            columns.push('quantity');
        }
        return columns;
    }

    init(productModelSparePartDefinitionReferences: ProductModelSparePartDefinitionReference[]): void {
        productModelSparePartDefinitionReferences.forEach(ref => {
            this.quantities[ref.id] = ref.quantity || 1;
        });
        this.displayedColumns = this.getDisplayedColumns();
        this._productModelSparePartDefinitionReferences = productModelSparePartDefinitionReferences;
    }

    addQuantity(productModelSparePartDefinitionReference: ProductModelSparePartDefinitionReference): void {
        this.quantities[productModelSparePartDefinitionReference.id]++;
    }

    removeQuantity(productModelSparePartDefinitionReference: ProductModelSparePartDefinitionReference): void {
        if (this.quantities[productModelSparePartDefinitionReference.id] > 1) {
            this.quantities[productModelSparePartDefinitionReference.id]--;
        }
    }

    addSparePartDefinitionToCart(productModelSparePartDefinitionReference: ProductModelSparePartDefinitionReference): void {
        let newItem = new StoreOrderItem();
        newItem.itemId = productModelSparePartDefinitionReference.sparePartDefinitionId;
        newItem.type = StoreOrderItemType.SPARE_PART;
        newItem.quantity = this.quantities[productModelSparePartDefinitionReference.id];
        this.addToCart.emit(newItem);
    }

    clickRow(productModelSparePartDefinitionReference: ProductModelSparePartDefinitionReference): void {
        this.rowClickAction.emit({ id: productModelSparePartDefinitionReference.sparePartDefinitionId, quantity: this.quantities[productModelSparePartDefinitionReference.id] });
    }

    sortChange(sort: Sort): void {
        this.sortAction.emit(sort);
    }

}