import * as L from 'leaflet';
import { Thing } from '../../../model';
import { MapFilterRequiredData } from '../../../model/map-filter-required-data';
import { MapContext } from '../map.component';
import { LocationForMap, ThingForMap } from '../map.service';
import { MapFilter } from './map-filter';

const MARKER_SVG = `<svg width="26" height="40" viewBox="0 0 26 40" xmlns="http://www.w3.org/2000/svg">
<path d="M1 13C1.00036 19.9142 8.08249 32.7303 11.3652 38.2994C12.1115 39.5656 13.8885 39.5656 14.6348 38.2994C17.9175 32.7303 24.9995 19.9142 24.9996 13C24.9996 6.37258 19.6274 1 13 1C6.37258 1 0.999654 6.37258 1 13Z" />
</svg>`;

export class ConnectionStatusMapFilter implements MapFilter {

    private icons: any[] = [];
    private config: any;
    private context: MapContext;
    private rankDescriptor = [
        { 'label': 'Offline', 'rank': 0, 'color': '#BDBDBD', 'clusterClass': 'cluster-offline', 'clusterSelectedClass': 'cluster-offline-selected' },
        { 'label': 'Online', 'rank': 1, 'color': '#39D17D', 'clusterClass': 'cluster-online', 'clusterSelectedClass': 'cluster-online-selected' }
    ];

    constructor(config: any) {
        this.config = config || {};
        this.config.label = this.config.label || "Connection Status";

        this.rankDescriptor.forEach((rd, i) => {
            this.icons.splice(i, 0,
                L.divIcon({
                    html: MARKER_SVG,
                    className: `marker-${rd.label.toLocaleLowerCase()}`,
                    iconSize: [26, 40],
                    iconAnchor: [13, 40],
                })
            );
            this.icons.push(
                L.divIcon({
                    html: MARKER_SVG,
                    className: `marker-${rd.label.toLocaleLowerCase()}-selected`,
                    iconSize: [26, 40],
                    iconAnchor: [13, 40],
                })
            );
        });
    }

    setContext(context: MapContext): void {
        this.context = context;
    }

    getConfig(): any {
        return this.config;
    }

    getRequiredData(): MapFilterRequiredData {
        const requireData = new MapFilterRequiredData();
        if (this.context == MapContext.THINGS) {
            requireData.things = true;
            requireData.locations = false;
            requireData.alerts = false;
        } else {
            requireData.things = true;
            requireData.locations = true;
            requireData.alerts = false;
        }
        return requireData;
    }

    computeRank(elements: ThingForMap[] | LocationForMap[], data: any): void {
        if (this.context == MapContext.THINGS) {
            elements.forEach(el => {
                el.rank = (el as ThingForMap).connectionStatus == 1 ? 1 : 0;
            });
        } else {
            elements.forEach(el => {
                const locationConnectionStatusMap = this.getLocationConnectionStatus(data.things)
                el.rank = locationConnectionStatusMap[el.id] || 0;
            });
        }
    }

    updateMarker(element: ThingForMap | LocationForMap, marker: any): void {
        if (element.selected) {
            marker.setIcon(this.icons[this.rankDescriptor.length + element.rank]);
        } else {
            marker.setIcon(this.icons[element.rank]);
        }
    }

    getRankDescriptors(): any[] {
        return this.rankDescriptor;
    }

    private getLocationConnectionStatus(things: Thing[]): { [locationId: string]: number } {
        const locationConnectionStatusMap: { [locationId: string]: number } = {};
        things.forEach(t => {
            const currentStatus = locationConnectionStatusMap[t.locationId];
            if (!currentStatus || currentStatus == 0) {
                locationConnectionStatusMap[t.locationId] = t.connectionStatus == 1 ? 1 : 0;
            }
        });
        return locationConnectionStatusMap;
    }
}