import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { Permissions } from '../../common/constants';
import { USER_THING_V2, USER_THINGS_EXPORT } from '../../common/endpoints';
import { Properties } from '../../common/properties';
import { Location, PagedList, Thing } from '../../model';
import { Tag } from '../../model/tag';
import { AuthenticationService } from '../../service/authentication.service';
import { CustomPropertyService, CustomPropertyType } from '../../service/custom-property.service';
import { DownloadingObject, DownloadService } from '../../service/download.service';
import { HttpService } from '../../service/http.service';
import { AbstractContextService } from '../../shared/class/abstract-context-service.class';
import { CompositePartComponent, MetricDetailComponent, PropertyComponent } from '../../shared/component';
import { CustomTableColumn, CustomTableService, PipedTableColumn } from '../../shared/custom-table';
import { DownloadStatus, DownloadType } from '../../shared/download-dialog/download-dialog.component';
import { AbstractListWidgetV2Service } from '../../shared/list-widget-v2/abstract-list-widget-v2.service';
import { TagService } from '../../shared/tags/tag.service';


@Injectable()
export class ThingListWidgetV2Service extends AbstractListWidgetV2Service<Thing> {

    constructor(
        @Inject(forwardRef(() => AuthenticationService)) protected authenticationService: AuthenticationService,
        @Inject(forwardRef(() => CustomPropertyService)) protected customPropertyService: CustomPropertyService,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => TagService)) private tagService: TagService,
        @Inject(forwardRef(() => DownloadService)) private downloadService: DownloadService,
    ) {
        super(authenticationService, customPropertyService);
    }

    getPagedList(page: number, size: number, sort: string[], metricNames: Set<string>, searchFields: string[], advancedSearchBody: any, location: Location, assigned: boolean, unassigned: boolean, currentThing: Thing, includeParentsOnly: boolean): Promise<PagedList<Thing>> {
        let params = this.getParams(metricNames, searchFields, advancedSearchBody, location, assigned, unassigned, currentThing, false, includeParentsOnly);
        params = params.set('page', page + '');
        params = params.set('size', size + '');
        if (sort && sort[0]) {
            if (sort[0].startsWith(AbstractListWidgetV2Service.COMPOSITE_SORT_PREFIX)) {
                sort = this.assignCompositeSort(sort);
            }
            params = params.set('sort', sort.join(','));
        }
        return firstValueFrom(this.httpService.get<PagedList<Thing>>(USER_THING_V2, params));
    }

    protected getPropertyColumn(col: PropertyComponent, columnName: string, defaultType: string): CustomTableColumn {
        switch (col.name) {
            case 'customer.name':
                return CustomTableService.newCustomerLinkColumn(this.getLabel(col, defaultType), 'customer').withSortField(col.name).withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            case 'location.name':
                return CustomTableService.newLocationLinkColumn(this.getLabel(col, defaultType), 'location').withSortField(col.name).withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            case 'serviceLevel':
                return CustomTableService.newPipedColumn(columnName, this.getLabel(col, defaultType), 'serviceLevel', this.getFilter(col, defaultType)).withSortField('serviceLevel.level').withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            case 'location.country':
            case 'location.timezone':
                return CustomTableService.newPipedColumn(columnName, this.getLabel(col, defaultType), col.name, this.getFilter(col, defaultType))
                    .withAlternativePath(col.name.replace('location', 'customer')).withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            case 'gpsPosition':
                return CustomTableService.newPipedColumn(columnName, this.getLabel(col, defaultType), 'gpsPosition', this.getFilter(col, defaultType))
                    .withAlternativePath('location.gpsPosition').withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            case 'tags':
                return CustomTableService.newTagColumn(columnName, this.getLabel(col, defaultType), col.name, this.getFilter(col, defaultType)).withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            case 'parentThingId':
                return CustomTableService.newParentThingColumn(this.getLabel(col, defaultType), columnName).withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            default:
                return (CustomTableService.newPipedColumn(columnName, this.getLabel(col, defaultType), col.name, this.getFilter(col, defaultType)) as PipedTableColumn).withArgument(this.getArgument(col))
                    .withSortField(col.name).withValueMap(this.getValueMap(col)).withColumnClass(col.columnClass).withShowHeader(col.showHeader);
        }
    }

    protected isColumnVisible(columnName: string): boolean {
        if (columnName.indexOf('serviceLevel') >= 0) {
            return this.authenticationService.hasPermission(Permissions.READ_SUBSCRIPTION) || this.authenticationService.hasPermission(Permissions.WRITE_SUBSCRIPTION);
        } else if (columnName.startsWith('customer.')) {
            return this.authenticationService.isOrganizationUser() || this.authenticationService.isPartnerUser();
        } else if (columnName.startsWith('location.')) {
            return !this.authenticationService.isLocationUser();
        } else {
            return true;
        }
    }

    protected getLabel(col: MetricDetailComponent | CompositePartComponent | PropertyComponent, defaultType: string): string {
        if (col.label) return col.label;

        if (col.name.indexOf('properties.') > -1) {
            const propNameType = this.getCustomPropertyNameAndType(col.name);
            return this.customPropertyService.getLabelByTypeAndName(propNameType.type, propNameType.name) || propNameType.name;
        } else if (col.name == 'parentThingId') {
            return 'parentThingProperty';
        } else {
            return Properties.getLabelByName(col.name, defaultType) || col.name;
        }
    }

    protected getCustomPropertyNameAndType(columnName: string): { name: string, type: CustomPropertyType } {
        let name = columnName;
        let type = CustomPropertyType.Thing;
        if (columnName.startsWith('customer.')) {
            type = CustomPropertyType.Customer;
            name = name.substr(9);
        } else if (columnName.startsWith('location.')) {
            type = CustomPropertyType.Location;
            name = name.substr(9);
        } else if (columnName.startsWith('thingDefinition.')) {
            type = CustomPropertyType.ThingDefinition;
            name = name.substr(16);
        }
        name = name.substr(11);
        return { name, type };
    }

    getParams(metricNames: Set<string>, searchFields: string[], advancedSearchBody: any, location: Location, assigned: boolean, unassigned: boolean, currentThing: Thing, forExport: boolean, includeParentsOnly: boolean): HttpParams {
        let params = new HttpParams();
        if (location) {
            params = params.set('locationId', location.id);
        } else if (this.contextService.getCurrentCustomer()) {
            params = params.set('customerId', this.contextService.getCurrentCustomer().id);
        } else if (this.contextService.getCurrentPartner()) {
            params = params.set('partnerId', this.contextService.getCurrentPartner().id);
        }
        metricNames.forEach(metricName => params = params.append('metricName', metricName));
        if (unassigned) {
            params = params.set('unassigned', 'true');
        }
        if (!assigned) {
            params = params.set('assigned', 'false');
        }
        if (currentThing) {
            params = params.set('parentThingId', currentThing.id);
        }
        if (advancedSearchBody) {
            if (advancedSearchBody.key) {
                params = params.set('searchText', "*" + advancedSearchBody.key + "*");
                searchFields.forEach(field => params = params.append('searchField', field));
            }
            if (advancedSearchBody.customer) {
                params = params.set('customerId', advancedSearchBody.customer);
            }
            if (advancedSearchBody.thingDefinitions && advancedSearchBody.thingDefinitions.length) {
                advancedSearchBody.thingDefinitions.forEach(id => params = params.append('thingDefinitionId', id));
            }
            if (advancedSearchBody.tags?.length) {
                advancedSearchBody.tags.forEach(tag => params = params.append('tagId', tag));
            }
            if (advancedSearchBody.name) {
                params = params.set('name', advancedSearchBody.name);
            }
            if (advancedSearchBody.serialNumber) {
                params = params.set('serialNumber', advancedSearchBody.serialNumber);
            }
            if (advancedSearchBody['customer.name']) {
                params = params.set('customer.name', advancedSearchBody['customer.name']);
            }
            if (advancedSearchBody['customer.code']) {
                params = params.set('customer.code', advancedSearchBody['customer.code']);
            }
            if (advancedSearchBody['location.name']) {
                params = params.set('location.name', advancedSearchBody['location.name']);
            }
            if (advancedSearchBody['thingDefinition.name']) {
                params = params.set('thingDefinition.name', advancedSearchBody['thingDefinition.name']);
            }
            if (advancedSearchBody['partner.name']) {
                params = params.set('partner.name', advancedSearchBody['partner.name']);
            }
            if (advancedSearchBody.serviceLevels && advancedSearchBody.serviceLevels.length) {
                advancedSearchBody.serviceLevels.forEach(id => params = params.append('serviceLevelId', id));
            }
            if (advancedSearchBody['serviceLevel.name']) {
                params = params.set('serviceLevel.name', advancedSearchBody['serviceLevel.name']);
            }
            if (advancedSearchBody['connectionStatus']) {
                params = params.set('connectionStatus', advancedSearchBody['connectionStatus']);
            }
            if (advancedSearchBody['connectionStatusLastUpdateTimestamp']) {
                params = params.set('connectionStatusLastUpdateTimestamp', advancedSearchBody['connectionStatusLastUpdateTimestamp']);
            }
            if (advancedSearchBody['cloudStatus']) {
                params = params.set('cloudStatus', advancedSearchBody['cloudStatus']);
            }
            if (advancedSearchBody['cloudStatusLastUpdateTimestamp']) {
                params = params.set('cloudStatusLastUpdateTimestamp', advancedSearchBody['cloudStatusLastUpdateTimestamp']);
            }
            if (advancedSearchBody['gpsPosition']) {
                params = params.set('gpsPosition', advancedSearchBody['gpsPosition']);
            }
            if (advancedSearchBody['location.gpsPosition']) {
                params = params.set('location.gpsPosition', advancedSearchBody['location.gpsPosition']);
            }
            if (advancedSearchBody.productModels && advancedSearchBody.productModels.length) {
                advancedSearchBody.productModels.forEach(id => params = params.append('productModelId', id));
            }
            if (advancedSearchBody['productModel.name']) {
                params = params.set('productModel.name', advancedSearchBody['productModel.name']);
            }
            if (advancedSearchBody.selectedThingIds && advancedSearchBody.selectedThingIds.length) {
                advancedSearchBody.selectedThingIds.forEach(id => params = params.append('selectedThingId', id));
            }
            if (advancedSearchBody['customer.countries'] && advancedSearchBody['customer.countries'].length) {
                params = params.append('customer.country', 'eq;' + advancedSearchBody['customer.countries'].join(','));
            }
            const properties = Object.keys(advancedSearchBody).filter(key => key.includes('properties.'));
            properties.forEach(property => params = params.set(property, advancedSearchBody[property]));
        }
        if (includeParentsOnly) {
            params = params.set('rootsOnly', 'true');
        }
        if (this.columnFieldNames && forExport) {
            this.columnFieldNames.forEach(columnFieldName => params = params.append('selectField', columnFieldName));
        }
        if (this.columnFieldLabels && forExport) {
            params = params.append('labels', this.columnFieldLabels.join(';'));
        }
        return params;
    }

    downloadCSV(params: HttpParams, fileName: string): void {
        params = params.set('language', this.authenticationService.getUser().language || 'en');
        const downloadingObject: DownloadingObject = {
            fileName: fileName || 'things.csv',
            uuid: null,
            status: DownloadStatus.DOWNLOADING,
            type: DownloadType.CSV,
            csvEndpoint: USER_THINGS_EXPORT,
            params: params
        }
        this.downloadService.addDownloadingObject(downloadingObject);
        this.downloadService.setVisible();
    }

    addTags(things: Thing[], tags: Tag[]): Thing[] {
        if (things?.length > 0) {
            things.forEach(thing => {
                let thingTags = [];
                const tagIds = thing.tagIds || [];
                tags.forEach(tag => {
                    if (tagIds.includes(tag.id)) {
                        thingTags.push(tag.name);
                    }
                });
                thing["tags"] = thingTags;
            });
        }
        return things;
    }
}