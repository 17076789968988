import { HttpParams } from "@angular/common/http";
import { Inject, Injectable, forwardRef } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { TASK_PROPERTY_DEFINITION_TASK_PROPERTY_DEFINITION_V2 } from "../../common/endpoints";
import { CustomPropertyDefinition, PagedList } from "../../model";
import { HttpService } from "../../service/http.service";
import { UiService } from "../../service/ui.service";

@Injectable()
export class ObjectArrayService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => UiService)) private uiService: UiService
    ) { }

    private invalidHeaderErrorMessage = "Invalid header ${headerName}";
    private propertyVisibilityErrorMessage = "Row ${rowIndex}: unable to assign a value to ${propertyName} due to inconsistency with visibility condition";
    private invalidColumnsErrorMessage = "Row ${rowIndex}: expected ${columnNumber} columns";
    private mandatoryErrorMessage = "Row ${rowIndex}: ${propertyName} is mandatory";
    private selectionModeValueErrorMessage = "Row ${rowIndex}: ${propertyName} value ${value} is invalid";
    private regularExpressionErrorMessage = "Row ${rowIndex}: ${validationErrorMessage}";
    private parseErrorMessage = "Row ${rowIndex}: ${propertyName} value ${value} cannot be parsed";
    private minErrorMessage = "Row ${rowIndex}: ${propertyName} value must be greater or equal to ${min}";
    private maxErrorMessage = "Row ${rowIndex}: ${propertyName} value must be less or equal to ${max}";
    private stepErrorMessage = "Row ${rowIndex}: ${propertyName} value is not valid. Closer values are ${previousValue} and ${nextValue}";

    getAllTaskPropertyDefinitions(taskPropertyDefinitionId: string): Promise<CustomPropertyDefinition[]> {
        let propertyDefinitions: CustomPropertyDefinition[] = [];
        let page = 0;
        return this.getRecursivelyAllTaskProperyDefinitionPages(taskPropertyDefinitionId, page, propertyDefinitions);
    }

    private getRecursivelyAllTaskProperyDefinitionPages(taskPropertyDefinitionId: string, page: number, propertyDefinitions: CustomPropertyDefinition[]): Promise<CustomPropertyDefinition[]> {
        return this.getTaskProperyDefinitionsPaged(taskPropertyDefinitionId, null, page, 100, ['order', 'asc'])
            .then(pagedTaskPropertyDef => {
                propertyDefinitions = propertyDefinitions.concat(pagedTaskPropertyDef.content);
                if (pagedTaskPropertyDef.last) {
                    return propertyDefinitions;
                } else {
                    return this.getRecursivelyAllTaskProperyDefinitionPages(taskPropertyDefinitionId, ++page, propertyDefinitions);
                }
            });
    }

    getTaskProperyDefinitionsPaged(taskPropertyDefinitionId: string, searchText: string, page: number, size: number, sort?: string[]): Promise<PagedList<CustomPropertyDefinition>> {
        let params = new HttpParams();
        params = params.set('page', page + '');
        params = params.set('size', size + '');
        if (sort && sort[0]) {
            params = params.set('sort', sort.join(','));
        }
        if (searchText) {
            params = params.set('searchText', searchText);
        }
        return firstValueFrom(this.httpService.get<PagedList<CustomPropertyDefinition>>(TASK_PROPERTY_DEFINITION_TASK_PROPERTY_DEFINITION_V2.replace('{id}', taskPropertyDefinitionId), params));
    }

    isPropertyDefintionVisible(definition: CustomPropertyDefinition, item: any) {   // item used for eval
        if (!definition.visibilityCondition) {
            return true;
        }
        let visibilityCondition = this.uiService.normalizeVisibilityCondition(definition.visibilityCondition);
        try {
            return eval(visibilityCondition);
        } catch {
            return false;
        }
    }

    extractObjectArraysFromCsv(fileContent: string, propertyDefinitions: CustomPropertyDefinition[]): { [propertyName: string]: any }[] {
        let objectArraysToImport: { [propertyName: string]: any }[] = [];
        const validHeaders = propertyDefinitions.map(prop => prop.name);
        if (fileContent) {
            const lines = fileContent.split(/[\r\n]+/).filter(line => !!line);
            if (lines?.length > 1) {
                const csvHeaders: string[] = lines[0].split(',');
                csvHeaders.forEach(header => {
                    if (!validHeaders.includes(header)) {
                        const error = this.invalidHeaderErrorMessage.replace("${headerName}", header);
                        throw new Error(error);
                    }
                });
                for (let rowIndex = 1; rowIndex < lines.length; rowIndex++) {
                    const values = lines[rowIndex].split(',');
                    if (values.length != csvHeaders.length) {
                        const error = this.invalidColumnsErrorMessage.replace("${rowIndex}", rowIndex.toString()).replace("${columnNumber}", csvHeaders.length.toString());
                        throw new Error(error);
                    }
                    let objectArray = {};
                    values.forEach((value, index) => {
                        objectArray[csvHeaders[index]] = value;
                    });
                    this.validateObjectArrayFromCsv(objectArray, propertyDefinitions, rowIndex);
                    objectArraysToImport.push(objectArray);
                }
            }
        }
        return objectArraysToImport;
    }

    private validateObjectArrayFromCsv(element: { [propertyName: string]: any }, propertyDefinitions: CustomPropertyDefinition[], rowIndex: number): void {
        propertyDefinitions.filter(p => p.mandatory).forEach(mandatoryPropDef => {
            if (!element[mandatoryPropDef.name]) {
                const error = this.mandatoryErrorMessage.replace("${rowIndex}", rowIndex.toString()).replace("${propertyName}", mandatoryPropDef.name);
                throw new Error(error);
            }
        });
        Object.keys(element).forEach(key => {
            if (element[key]) {
                const propertyDefinition = propertyDefinitions.find(propDef => propDef.name == key);
                if (!this.isPropertyDefintionVisible(propertyDefinition, element)) {
                    const error = this.propertyVisibilityErrorMessage.replace("${rowIndex}", rowIndex.toString()).replace("${propertyName}", propertyDefinition.name);
                    throw new Error(error);
                }
                if (propertyDefinition.selectionMode && propertyDefinition.values?.length && !propertyDefinition.values.some(val => val.value == element[key])) {
                    const error = this.selectionModeValueErrorMessage.replace("${rowIndex}", rowIndex.toString()).replace("${value}", element[key]).replace("${propertyName}", propertyDefinition.name);
                    throw new Error(error);
                }
                if (propertyDefinition.type == 'INTEGER' || propertyDefinition.type == 'FLOAT') {
                    this.checkNumberValue(element, propertyDefinition, rowIndex);
                } else if (propertyDefinition.type == 'BOOLEAN') {
                    element[propertyDefinition.name] = (typeof element[propertyDefinition.name] === 'boolean') ? element[propertyDefinition.name] : (element[propertyDefinition.name] === 'true');
                } else {    // STRING
                    if (propertyDefinition.regularExpression) {
                        if (!propertyDefinition.regularExpression.startsWith('^') && !propertyDefinition.regularExpression.endsWith('$')) {
                            propertyDefinition.regularExpression = '^' + propertyDefinition.regularExpression + '$';
                        }
                        const regexp = new RegExp(propertyDefinition.regularExpression);
                        if (!regexp.test(element[key])) {
                            const validationErrorMessage = propertyDefinition.validationErrorMessage;
                            const error = this.regularExpressionErrorMessage.replace("${rowIndex}", rowIndex.toString()).replace("${validationErrorMessage}", validationErrorMessage);
                            throw new Error(error);
                        }
                    }
                }
            }
        });
    }

    private checkNumberValue(element: { [propertyName: string]: any }, propertyDefinition: CustomPropertyDefinition, rowIndex: number): void {
        const parsedValue: number = this.parseNumber(element[propertyDefinition.name], propertyDefinition.type);
        if (isNaN(parsedValue)) {
            const error = this.parseErrorMessage.replace("${rowIndex}", rowIndex.toString()).replace("${value}", element[propertyDefinition.name]).replace("${propertyName}", propertyDefinition.name);
            throw new Error(error);
        } else {
            element[propertyDefinition.name] = parsedValue;
        }
        this.checkMinValue(parsedValue, propertyDefinition, rowIndex);
        this.checkMaxValue(parsedValue, propertyDefinition, rowIndex);
        this.checkStepValue(element[propertyDefinition.name], propertyDefinition, rowIndex);
    }

    private parseNumber(value: string, type: string): number {
        return type == 'INTEGER' ? parseInt(value) : parseFloat(value);
    }

    private checkMinValue(value: number, propertyDefinition: CustomPropertyDefinition, rowIndex: number): void {
        const min = this.parseNumber(propertyDefinition.minValue, propertyDefinition.type);
        if (!isNaN(min) && value < min) {
            const error = this.minErrorMessage.replace("${rowIndex}", rowIndex.toString()).replace("${min}", propertyDefinition.minValue).replace("${propertyName}", propertyDefinition.name);
            throw new Error(error);
        }
    }

    private checkMaxValue(value: number, propertyDefinition: CustomPropertyDefinition, rowIndex: number): void {
        const max = this.parseNumber(propertyDefinition.maxValue, propertyDefinition.type);
        if (!isNaN(max) && value > max) {
            const error = this.maxErrorMessage.replace("${rowIndex}", rowIndex.toString()).replace("${max}", propertyDefinition.maxValue).replace("${propertyName}", propertyDefinition.name);
            throw new Error(error);
        }
    }

    private checkStepValue(stringValue: string, propertyDefinition: CustomPropertyDefinition, rowIndex: number): void {
        const step = this.parseNumber(propertyDefinition.stepValue, propertyDefinition.type);
        if (!isNaN(step)) {
            const value = parseFloat(stringValue);
            const offset = this.parseNumber(propertyDefinition.minValue, propertyDefinition.type);
            const reminder = ((isNaN(offset) ? value * 1000 : value * 1000 - offset * 1000)) % (step * 1000) / 1000;
            const limit1 = (value * 1000 - reminder * 1000) / 1000;
            const limit2 = limit1 > value ? limit1 - step : limit1 + step;
            const previous = limit1 > limit2 ? limit2 : limit1;
            const next = limit1 > limit2 ? limit1 : limit2;
            if (reminder !== 0) {
                const error = this.stepErrorMessage.replace("${rowIndex}", rowIndex.toString()).replace("${previousValue}", previous.toString()).replace("${nextValue}", next.toString()).replace("${propertyName}", propertyDefinition.name);
                throw new Error(error);
            }
        }
    }
}