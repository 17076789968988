import { AfterViewInit, Component, ContentChildren, forwardRef, Inject, Input, OnDestroy, OnInit, QueryList, ViewChild } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { MatTable } from '@angular/material/table';
import * as _ from 'lodash';
import * as moment from 'moment';
import { firstValueFrom, Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { ErrorMessages, Permissions } from '../../common/constants';
import { ThingService } from '../../dashboard-area/thing/thing.service';
import { DataExportConfigurationProperties, Metric, Thing } from '../../model';
import { AbstractExportContextService } from '../../service/abstract-export-context.service';
import { AuthenticationService } from '../../service/authentication.service';
import { DataExportConfigurationService } from '../../service/data-export-configuration.service';
import { CUSTOM_RANGE, DateRangeName, DateRangeService, PeriodVariable } from '../../service/date-range.service';
import { DownloadService } from '../../service/download.service';
import { FieldService } from '../../service/field.service';
import { MetricService } from '../../service/metric.service';
import { AbstractThingContextService } from '../../shared/class/abstract-thing-context-service.class';
import { PreselectedRangeComponent } from '../../shared/component/daterange-picker/preselected-range.component';
import { MetricDetailComponent } from '../../shared/component/metric/metric-detail.component';
import { DownloadStatus, DownloadType } from '../../shared/download-dialog/download-dialog.component';
import { ErrorUtility } from '../../utility/error-utility';
import { FieldMap, MODE, MultiMetricListData, MultiMetricListService } from './multi-metric-list.service';

let nextId = 0;

@Component({
    selector: 'multi-metric-list-widget',
    template: require('./multi-metric-list.component.html'),
    styles: [require('./multi-metric-list.component.css')],
    providers: [MultiMetricListService, ThingService]
})
export class MultiMetricListComponent extends PreselectedRangeComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() title: string;

    @Input() mode: string = "TABLE";

    @Input() maxHeight: string;

    @Input() styleClass: string;

    @Input() pageSize: number;

    @Input() startDateFieldRef: string;

    @Input() endDateFieldRef: string;

    @Input() startDate: string;

    @Input() endDate: string;

    @Input() disableFloatHead: boolean;

    @Input() exportEnabled: boolean;

    @Input() filterEnabled: boolean = true;

    @Input() showTimestamp: boolean = true;

    @Input() refreshInterval: string = 'PT30S'; // 30 secs defult

    @Input() periodRef: string;

    @Input() thingId: string;

    @Input() timestampFilter: string;

    @Input() exportFileName: string;

    @Input() ignoreGlobalExportButton: boolean;

    @Input() collapsible: boolean;

    @Input() description: string;

    @Input() initialPeriodEmpty: boolean;

    @ContentChildren(MetricDetailComponent) metricComponents: QueryList<MetricDetailComponent>;

    @ViewChild(MatTable) matTable: MatTable<any>;

    metrics: { name: string, id: string, label: string, unit: string, filter: any }[];
    state: { data: MultiMetricListData[], loaded: boolean, error: string, infiniteScrollActive: boolean };
    error: string = null;
    visibleColumns: string[];
    initCompleted: boolean;
    timezone: string;
    maxDaysBack: number;
    visibleRanges: string[];
    initialRange: DateRange<moment.Moment>;

    // filler loading properties
    fillerRowCount: number = 20;
    fillerRowHeight: number = 10;
    fillerRowHeightWithSpace: number = 12;
    fillerRowOffset: number = 10;

    private alive: boolean;
    private fieldMap: FieldMap;
    private fieldNames: string[];
    private refreshIntervalMillis: number;
    private exportId: string;
    private exportVisibilitySubscription: Subscription;
    private fieldServiceSubscription: Subscription;
    private configuration: DataExportConfigurationProperties;
    private range: DateRange<moment.Moment>;

    constructor(
        @Inject(forwardRef(() => MultiMetricListService)) private multiMetricListService: MultiMetricListService,
        @Inject(forwardRef(() => FieldService)) private fieldService: FieldService,
        @Inject(forwardRef(() => DownloadService)) private downloadService: DownloadService,
        @Inject(forwardRef(() => AbstractThingContextService)) private thingContextService: AbstractThingContextService,
        @Inject(forwardRef(() => DateRangeService)) protected dateRangeService: DateRangeService,
        @Inject(forwardRef(() => AbstractExportContextService)) private exportService: AbstractExportContextService,
        @Inject(forwardRef(() => MetricService)) private metricService: MetricService,
        @Inject(forwardRef(() => ThingService)) private thingService: ThingService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => DataExportConfigurationService)) private dataExportConfigurationService: DataExportConfigurationService
    ) {
        super(dateRangeService);
    }

    ngOnInit(): void {
        if (this.initialPeriodEmpty) {
            this.defaultPeriodValue = null;
        } else {
            this.defaultPeriodValue = this.defaultPeriodValue || DateRangeName.TODAY;
        }
        this.filterPeriods = this.filterPeriods ? this.filterPeriods : [DateRangeName.LAST_1_HOUR, DateRangeName.LAST_2_HOURS, DateRangeName.LAST_6_HOURS, DateRangeName.LAST_12_HOURS, DateRangeName.LAST_24_HOURS, DateRangeName.TODAY, DateRangeName.YESTERDAY, DateRangeName.LAST_7_DAYS, DateRangeName.THIS_WEEK, DateRangeName.LAST_WEEK, DateRangeName.LAST_30_DAYS, DateRangeName.THIS_MONTH, DateRangeName.LAST_MONTH, DateRangeName.LAST_6_MONTHS, DateRangeName.LAST_12_MONTHS, DateRangeName.THIS_YEAR, DateRangeName.LAST_YEAR, CUSTOM_RANGE];
        this.fieldMap = {};
        this.alive = true;
        this.pageSize = this.pageSize || 20;
        this.refreshIntervalMillis = Math.max(moment.duration(this.refreshInterval).asMilliseconds(), 5000); // min 5 sec
        if (this.defaultPeriodValue) {
            var value = this.getPeriod();
            if (value) {
                this.initialRange = new DateRange(value.range.start, value.range.end);
                this.range = _.cloneDeep(this.initialRange);
            }
        }
        if (!this.maxHeight) {
            this.maxHeight = 500 + 'px';
        }
        if (!this.styleClass) {
            this.styleClass = 'no-border';
        } else {
            this.styleClass = 'no-border ' + this.styleClass;
        }
        if (this.periodRef) {
            this.startDateFieldRef = null;
            this.endDateFieldRef = null;
        }
        this.multiMetricListService.state$
            .pipe(takeWhile(() => this.alive))
            .subscribe(state => {
                this.fillerRowCount = state.data?.length || this.fillerRowCount;
                this.state = state;
                if (this.matTable) {
                    this.matTable.renderRows();
                }
            });
        this.exportEnabled = this.exportEnabled && this.authenticationService.hasPermission(Permissions.EXPORT_DATA);
        if (this.exportEnabled && !this.ignoreGlobalExportButton) {
            this.subscribeToExportServices();
        }
        this.timezone = this.authenticationService.getUser()?.timezone;
        if (this.thingContextService.getCurrentWorkSession() || this.thingContextService.getCurrentAlert()) {
            this.periodRef = null;
            this.startDateFieldRef = null;
            this.endDateFieldRef = null;
            this.filterEnabled = false;
            const alertWs = this.thingContextService.getCurrentWorkSession() || this.thingContextService.getCurrentAlert();
            this.startDate = alertWs.startTimestamp as any;
            this.endDate = alertWs.endTimestamp as any;
        }
    }

    ngAfterViewInit(): void {
        if (this.exportEnabled) {
            this.dataExportConfigurationService.getConfiguration().then(configuration => {
                this.configuration = configuration;
                this.updateDateLimit();
                this.preInit();
            }).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR));
        } else {
            this.visibleRanges = this.filterPeriods;
            this.preInit();
        }
    }

    private preInit(): void {
        if (!this.mode || (this.mode !== MODE.LOG && this.mode !== MODE.TABLE)) {
            console.error(`Invalid mode attibute: expected LOG or TABLE but found ${this.mode}`);
            return;
        }
        this.fieldNames = [this.startDateFieldRef, this.endDateFieldRef, this.periodRef];
        this.metricComponents.forEach(component => {
            if (component.inputsFunction) {
                this.fieldNames = this.fieldNames.concat(component.inputsFunction);
            }
        })
        if (this.thingId) {
            this.thingService.getThingById(this.thingId).then(thing => {
                this.metricService.getMetricsByThingDefinitionId(thing.thingDefinitionId).then(metrics => {
                    this.init(thing, metrics);
                }).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR));
            }).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR));
        } else {
            let thing = this.thingContextService.getCurrentThing();
            this.thingContextService.getMetrics().then(thingMetrics => {
                this.init(thing, thingMetrics);
            });
        }
    }

    private init(thing: Thing, thingMetrics: Metric[]): void {
        this.metrics = this.multiMetricListService.init(thing, thingMetrics, this.metricComponents, this.mode, this.pageSize, this.refreshIntervalMillis);
        this.initCompleted = true;
        this.fieldServiceSubscription = this.fieldService.subscribeToFields(this.fieldNames)
            .pipe(takeWhile(() => this.alive))
            .subscribe(fieldMap => {
                this.fieldMap = _.cloneDeep(fieldMap);
                let start = this.range?.start ?? moment.invalid();
                let end = this.range?.end ?? moment.invalid();
                let customStartDate = null;
                let customEndDate = null;
                if (this.periodRef) {
                    const periodVariable: PeriodVariable = fieldMap[this.periodRef];
                    if (periodVariable && periodVariable.start) {
                        customStartDate = moment(periodVariable.start);
                    }
                    if (periodVariable && periodVariable.end) {
                        customEndDate = moment(periodVariable.end);
                    }
                } else if (this.startDateFieldRef || this.endDateFieldRef) {
                    if (this.startDateFieldRef && fieldMap[this.startDateFieldRef]) {
                        customStartDate = moment(fieldMap[this.startDateFieldRef]);
                    }
                    if (this.endDateFieldRef && fieldMap[this.endDateFieldRef]) {
                        customEndDate = moment(fieldMap[this.endDateFieldRef]);
                    }
                }
                if (customStartDate == null && customEndDate == null) {
                    if (this.startDate || this.endDate) {
                        end = this.endDate ? moment(this.endDate) : moment();
                        start = this.startDate ? moment(this.startDate) : end.clone().subtract(6, 'days');
                    }
                } else {
                    end = customEndDate != null ? customEndDate : moment();
                    start = customStartDate != null ? customStartDate : end.clone().subtract(6, 'days');
                }
                Promise.resolve().then(() => {
                    const range = new DateRange(start, end);
                    this.selectPeriod(range);
                });
            });
        this.getVisibleColumns();
    }

    ngOnDestroy(): void {
        this.multiMetricListService.stopListen();
        this.fieldService.unsubscribeFromFields(this.fieldNames);
        this.alive = false;
        if (this.exportId) {
            this.exportService.unsubscribeFromExport(this.exportId);
        }
        if (this.exportVisibilitySubscription) {
            this.exportVisibilitySubscription.unsubscribe();
        }
        if (this.fieldServiceSubscription) {
            this.fieldServiceSubscription.unsubscribe();
        }
    }

    selectPeriod(range: DateRange<moment.Moment>): void {
        this.range = range;
        const start = range && range.start.isValid() ? range.start.valueOf() : null;
        const end = range && range.end.isValid() ? range.end.valueOf() : null;
        this.multiMetricListService.selectPeriod(start, end, this.fieldMap, this.enableListening(range));
    }

    infiniteScroll($event): void {
        const el = <any>($event.srcElement || $event.target);
        const minValue: number = el.clientHeight - 2;
        const maxValue: number = el.clientHeight + 2
        if (minValue <= Math.trunc(el.scrollHeight - el.scrollTop) && Math.trunc(el.scrollHeight - el.scrollTop) <= maxValue && !this.state.infiniteScrollActive) {
            const start = this.range && this.range.start ? this.range.start : null;
            if (start.isValid()) {
                this.multiMetricListService.infiniteScroll(start.valueOf(), this.fieldMap, this.enableListening(this.range));
            } else {
                this.multiMetricListService.infiniteScroll(null, this.fieldMap, this.enableListening(this.range));
            }
        }
    }

    private enableListening(range: DateRange<moment.Moment>): boolean {
        if (!range) {
            return true;
        }
        const end = range.end;
        const duration = moment.duration(5, 'seconds');
        return !end.isValid() || end.isSameOrAfter(moment().subtract(duration));
    }

    exportData(): void {
        this.error = null;
        firstValueFrom(this.multiMetricListService.getExportJobKey(this.range)).then(resp => {
            let fileName = this.exportService.resolveExportFileNamePlaceholders(this.exportFileName, resp.exportJobKey.startTimestamp, resp.exportJobKey.endTimestamp) || ((resp.thing.serialNumber ? resp.thing.serialNumber : "null") + "-" +
                this.downloadService.formatDateYYYYMMDD(resp.exportJobKey.startTimestamp) + "-" + this.downloadService.formatDateYYYYMMDD(resp.exportJobKey.endTimestamp) + (resp.exportJobKey.xlsx ? ".xlsx" : ".zip"));
            const downloadingObject = {
                fileName: fileName,
                uuid: resp.exportJobKey.uuid,
                status: DownloadStatus.DOWNLOADING,
                type: (resp.exportJobKey.xlsx ? DownloadType.XLSX_METRICS : DownloadType.ZIP_METRICS)
            }
            this.downloadService.addDownloadingObject(downloadingObject);
            this.downloadService.setVisible();
        }).catch(err => {
            if (err.status == 500 && err.error.message.startsWith("503")) {
                this.error = ErrorMessages.SERVER_TOO_BUSY;
            } else {
                this.error = ErrorUtility.getMessage(err, ErrorMessages.GENERIC_ERROR);
            }
        }
        );
    }

    private getVisibleColumns(): void {
        let columns = [];
        if (this.showTimestamp) {
            columns.push('timestamp');
        }
        if (this.mode == "TABLE") {
            columns = columns.concat(this.metrics.map((m, index) => { return m.name + index }));
        } else if (this.mode == "LOG") {
            columns.push("value");
        }
        this.visibleColumns = _.cloneDeep(columns);
    }

    private subscribeToExportServices(): void {
        this.exportId = "multi-metric-list-" + nextId++;
        this.exportService.subscribeToExport(this.exportId, this.title || "Multi Metric List").subscribe(() => this.exportData());
        this.exportVisibilitySubscription = this.exportService.getIsExportButtonPresent().subscribe(isExportButtonPresent => {
            this.exportEnabled = !isExportButtonPresent;
        });
    }

    private updateDateLimit(): void {
        const configurationProperty = this.configuration?.maxMonthsConfiguration?.find(conf => this.metricComponents?.length <= conf.maxMetrics || conf.maxMetrics == null);
        const monthLimit = configurationProperty?.maxMonths != null ? configurationProperty.maxMonths : 0;
        if (monthLimit == 0) {
            this.error = "Invalid maxPeriod configuration";
            return;
        }
        this.maxDaysBack = monthLimit * 30;
        this.visibleRanges = this.dataExportConfigurationService.updateVisibleRanges(monthLimit, this.allowedPeriods, this.filterPeriods || [DateRangeName.TODAY, DateRangeName.YESTERDAY, DateRangeName.LAST_24_HOURS, DateRangeName.LAST_7_DAYS, DateRangeName.LAST_30_DAYS, DateRangeName.THIS_MONTH, DateRangeName.LAST_MONTH, DateRangeName.LAST_12_MONTHS, DateRangeName.THIS_YEAR, 'CUSTOM']);
    }

}

