import { Component, ContentChild, EventEmitter, forwardRef, Inject, Input, Output, TemplateRef, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { DateRange } from "@angular/material/datepicker";
import { Moment } from "moment";
import { DateRangeName, DateRangeService } from "../../../service/date-range.service";
import { PreselectedRangeComponent } from "../../../shared/component/daterange-picker/preselected-range.component";
import { SearchTargetType } from "../../search-field/search-field.component";
import { SimpleSearchImportDialogComponent } from "./simple-search-import-dialog.component";

@Component({
    selector: 'simple-search',
    template: require('./simple-search.component.html'),
    styles: [require('./simple-search.component.css')],
})
export class SimpleSearchComponent extends PreselectedRangeComponent {

    @Input() showDaterangePicker: boolean;

    @Input() showAdvancedSearchToggle: boolean;

    @Input() initialRangeName: DateRangeName;

    @Input() showActions: boolean;

    @Input() enableImport: boolean;

    @Input() importButtonLabel: string = 'importFromCsvButton';

    @Input() importDialog: boolean = true;

    @Input() enableExport: boolean;

    @Input() importTitle: string;

    @Input() csvExample: string;

    @Input() exportTitle: string = 'exportAllButton';

    @Input() showSearchBar: boolean = true;

    @Input() popupQueryFieldRef: string;

    @Input() popupSearchTarget: SearchTargetType;

    @Input() popupQuery: { property: string, predicate: string, value: any }[];

    @Input() fullWidth: boolean;

    @Input() actions: SimpleSearchAction[];

    @Output() selectPeriod = new EventEmitter();

    @Output() search = new EventEmitter();

    @Output() toggleAdvancedSearchVisibility = new EventEmitter();

    @Output() export = new EventEmitter();

    @Output() import = new EventEmitter();

    @Output() actionEvent = new EventEmitter();

    @ViewChild(SimpleSearchImportDialogComponent) dialog: SimpleSearchImportDialogComponent;

    @ContentChild(TemplateRef) leftSideElement: TemplateRef<any>;

    searchControl: FormControl;

    constructor(
        @Inject(forwardRef(() => DateRangeService)) protected dateRangeService: DateRangeService
    ) { super(dateRangeService) }

    ngOnInit(): void {
        this.searchControl = new FormControl();
    }

    handleEnterKey(ev: KeyboardEvent): void {
        if (ev.keyCode === 13) {
            this.simpleSearch();
        }
    }

    simpleSearch(): void {
        const key = this.searchControl.value;
        const body = Object.assign({}, { key: key || null });
        this.search.emit(body);
    }

    emitSelectPeriod(range: DateRange<Moment>): void {
        const q = this.searchControl.value;
        this.selectPeriod.emit({ range: range, searchKey: q });
    }

    showHideAdvancedSearch(): void {
        this.toggleAdvancedSearchVisibility.emit();
    }

    reset(): void {
        this.searchControl.reset();
    }

    emitExport(): void {
        this.export.emit();
    }

    handleImport(): void {
        if (this.importDialog) {
            this.openImportDialog();
        } else {
            this.emitImport();
        }
    }

    openImportDialog(): void {
        this.dialog.open();
    }

    emitImport(body?: FormData): void {
        this.import.emit(body);
    }

    emitAction(action: string): void {
        this.actionEvent.emit(action);
    }

    isActionMenuVisible(): boolean {
        return this.showActions && (this.enableExport || this.enableImport || this.actions?.some(a => a.visible));
    }
}

export class SimpleSearchAction {
    visible: boolean;
    name: string;
    title: string;
}