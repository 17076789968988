import { Component, forwardRef, Inject, NgZone, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { BehaviorSubject, Subscription } from "rxjs";
import { CustomMatPaginatorIntl } from "../../../dashboard-area/shared/custom-mat-paginator-intl.service";
import { WorkSessionService } from "../../../dashboard-area/shared/work-session.service";
import { PagedList, WorkSession } from "../../../model";
import { AbstractExportContextService } from "../../../service/abstract-export-context.service";
import { AppService } from "../../../service/app.service";
import { AuthenticationService } from "../../../service/authentication.service";
import { FieldService } from "../../../service/field.service";
import { NavigationService } from "../../../service/navigation.service";
import { SocketService } from "../../../service/socket.service";
import { ThingContextService } from "../../../service/thing-context.service";
import { SearchTargetType } from "../../../shared/search-field/search-field.component";
import { ErrorUtility } from "../../../utility/error-utility";
import { DetailsModeType } from "../../shared/alert-work-session-list";
import { ThingListWidgetV2Service } from "../../thing-list/thing-list-widget-v2.service";
import { AbstractWorkSessionListWidgetV2Component } from "../abstract-work-session-list-widget-v2.component";
import { ActiveWorkSessionListWidgetV2Service } from "./active-work-session-list-widget-v2.service";

@Component({
    selector: 'active-work-session-list-widget-v2',
    template: require('./active-work-session-list-widget-v2.component.html'),
    styles: [require('../../../shared/list-widget-v2/list-widget-v2.css')],
    providers: [ActiveWorkSessionListWidgetV2Service, ThingListWidgetV2Service, WorkSessionService, { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl }]
})
export class ActiveWorkSessionListWidgetV2Component extends AbstractWorkSessionListWidgetV2Component implements OnDestroy {

    hidden: boolean;

    private workSessionsSubscriptionId: number;
    private workSessionSubscription: Subscription;

    constructor(
        @Inject(forwardRef(() => ActiveWorkSessionListWidgetV2Service)) private activeWorkSessionListWidgetV2Service: ActiveWorkSessionListWidgetV2Service,
        @Inject(forwardRef(() => NavigationService)) protected navigationService: NavigationService,
        @Inject(forwardRef(() => AppService)) appService: AppService,
        @Inject(forwardRef(() => WorkSessionService)) protected workSessionService: WorkSessionService,
        @Inject(forwardRef(() => AuthenticationService)) authenticationService: AuthenticationService,
        @Inject(forwardRef(() => AbstractExportContextService)) exportService: AbstractExportContextService,
        @Inject(forwardRef(() => MatDialog)) dialog: MatDialog,
        @Inject(forwardRef(() => SocketService)) private socketService: SocketService,
        @Inject(forwardRef(() => ThingContextService)) private thingContextService: ThingContextService,
        @Inject(forwardRef(() => NgZone)) private zone: NgZone,
        @Inject(forwardRef(() => FieldService)) fieldService: FieldService
    ) {
        super(activeWorkSessionListWidgetV2Service, navigationService, appService, workSessionService, authenticationService, exportService, dialog, fieldService);
    }

    protected init(): void {
        this.checkIfHidden();
        this.handleAdvancedSearchLayoutType("active-work-session-list-popup-advanced-search", SearchTargetType.ACTIVE_WORK_SESSIONS);
        this.localStorageKey = 'workSessionAdvancedSearchFieldsValues';
    }

    private checkIfHidden(): void {
        if (!this.thingContextService.getCurrentThing()) {
            if (this.authenticationService.isOrganizationUser() || this.authenticationService.isPartnerUser()) {
                this.hidden = !this.authenticationService.hasFeature('multipleThingAggregationForOrgPartner');
            } else {
                this.hidden = !this.authenticationService.hasFeature('multipleThingAggregationForCustomer');
            }
        }
    }

    ngOnDestroy(): void {
        this.unsubscribeFromSubscriptions();
        this.unsubscribeFormExport();
        this.unsubscribeFromPeriodField();
    }

    private unsubscribeFromSubscriptions(): void {
        if (this.workSessionsSubscriptionId) {
            this.socketService.delete(this.workSessionsSubscriptionId);
            this.workSessionsSubscriptionId = null;
        }
        if (this.workSessionSubscription) {
            this.workSessionSubscription.unsubscribe();
            this.workSessionSubscription = null;
        }
    }

    protected getWorkSessionList(): void {
        this.unsubscribeFromSubscriptions();
        this.workSessionSubscription = this.subscribeToActiveWorkSession().subscribe(pagedList => {
            if (pagedList) {
                this.error = null;
                this.activeWorkSessionListWidgetV2Service.updatePageListContent(pagedList.content, this.workSessionDefinitions, this.displayedColumns, false, this.detailsMode, this.clickOnRowBehaviour);
                this.updateElementList(pagedList);
            }
        })
    }

    private subscribeToActiveWorkSession(): BehaviorSubject<PagedList<WorkSession>> {
        let subject = new BehaviorSubject(null);
        this.activeWorkSessionListWidgetV2Service.getPagedList(this.pageIndex, this.pageSize, this.sort, null, this.searchFields, this.advancedSearchBody, null, this.includeSubThingsWorkSessions)
            .then(pagedList => {
                this.zone.run(() => subject.next(pagedList));
                const topic = this.workSessionService.getWorkSessionsTopic();
                this.workSessionsSubscriptionId = this.socketService.subscribe({
                    topic,
                    callback: () => {
                        this.activeWorkSessionListWidgetV2Service.getPagedList(this.pageIndex, this.pageSize, this.sort, null, this.searchFields, this.advancedSearchBody, null, this.includeSubThingsWorkSessions).then(pagedList => this.zone.run(() => subject.next(pagedList))).catch(err => this.error = ErrorUtility.getMessage(err));
                    }
                });
            }
            ).catch(err => this.error = ErrorUtility.getMessage(err));
        return subject;
    }

    refreshList(data?: { pageIndex: number, pageSize: number, advancedSearchBody: any, sort: string[] }): void {
        this.loaded = false;
        if (data) {
            this.pageIndex = data.pageIndex;
            this.pageSize = data.pageSize;
            this.advancedSearchBody = data.advancedSearchBody;
            this.sort = data.sort;
        }
        this.getWorkSessionList();
    }

    protected goToWorkSessionDetails(id: string): void {
        if (this.detailsMode == DetailsModeType.PAGE) {
            this.navigationService.navigateTo(['/dashboard/active_work_session_details', id]);
        } else if (this.detailsMode == DetailsModeType.POPUP) {
            this.openDialog(id, false);
        }
    }

    protected subscribeToExportServices(): void {
        this.exportId = "active-work-session-list-" + this.getNextId();
        this.exportService.subscribeToExport(this.exportId, this.title || "Work Sessions").subscribe(() => this.export());
        this.subscribeToExportVisibility();
    }

    protected deleteWorkSession(id: string): void {
        throw new Error("Method not supported.");
    }

}