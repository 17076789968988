import { AfterViewInit, Component, forwardRef, Inject, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from '../../../service/app.service';
import { FieldService } from '../../../service/field.service';
import { AbstractSearchFieldComponent } from '../abstract-search-field.component';
import { SearchFieldService } from '../search-field.service';

@Component({
    selector: 'actions-search-field',
    template: require('./actions-search-field.component.html'),
    styles: [require('../search-field.component.css')],
})
export class ActionsSearchFieldComponent extends AbstractSearchFieldComponent implements AfterViewInit {

    constructor(
        @Inject(forwardRef(() => FieldService)) fieldService: FieldService,
        @Inject(forwardRef(() => SearchFieldService)) searchFieldService: SearchFieldService,
        @Inject(forwardRef(() => MatDialog)) matDialog: MatDialog,
        @Inject(forwardRef(() => ViewContainerRef)) vcRef: ViewContainerRef,
        @Inject(forwardRef(() => AppService)) appService: AppService
    ) {
        super(fieldService, searchFieldService, matDialog, vcRef, appService);
    }

    ngAfterViewInit(): void {
        const savedFieldsValues = localStorage.getItem(this.id) ? JSON.parse(localStorage.getItem(this.id)) : null;
        this.emitAdvancedSearch(savedFieldsValues || {});
    }

    openAddPropertiesDialog(advancedSearchProperties: { name: string, label: string }[]): void {
    }

    closeAddPropertiesDialog(updatedSearchFields: string[]): void {
    }

    performClear(): void {
        this.clearInputs();
        localStorage.removeItem(this.id);
        this.emitAdvancedSearch(this.advancedSearchBody);
    }

    protected updateAdvancedSearch(fieldsToSaveBody: any): void {
    }

    protected getEncodedRawValues(): void {
        let body: any = {};
        return body;
    }

    protected getRawValues() {
        let body: any = {};
        return body;
    }

    protected refreshInputConfigurations(): void {
        this.filtered = false;
    }
}