import { HttpParams } from "@angular/common/http";
import { Component, Inject, ViewChild, forwardRef } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ErrorMessages } from "../../common/constants";
import { OrganizationAddDialogComponent } from "../../dashboard-area/organization/organization-add-dialog/organization-add-dialog.component";
import { ProductModel, ProductModelPart, ThingDefinition, ThingInventoryManagementType } from "../../model";
import { AuthenticationService } from "../../service/authentication.service";
import { MetricService } from "../../service/metric.service";
import { ProductModelPartService } from "../../service/product-model-part.service";
import { ProductModelService } from "../../service/product-model.service";
import { ThingDefinitionService } from "../../service/thing-definition.service";
import { ErrorUtility } from "../../utility/error-utility";
import { DataExportMetricSelectorTableComponent } from "./data-export-metric-selector-table.component";
import { DataExportDataElement, DataExportMetric } from "./data-export-widget-schedule-page.component";

@Component({
    selector: 'data-export-widget-add-metric-data-dialog',
    template: require('./data-export-widget-add-metric-data-dialog.component.html')
})
export class DataExportWidgetAddMetricDataDialog {

    @ViewChild(DataExportMetricSelectorTableComponent) metricSelector: DataExportMetricSelectorTableComponent;

    dataExportMetrics: DataExportMetric[];
    isThingSelected: boolean;
    selectedThingDefinitionId: string;
    selectedProductModelId: string;
    selectedDataExportMetrics: DataExportMetric[];
    loadingMetrics: boolean;
    thingDefinitions: ThingDefinition[];
    productModels: ProductModel[];
    resourceLoaded: boolean;
    thingInventoryManagement: ThingInventoryManagementType;
    error: string;

    private currentDataExportMetrics: DataExportMetric[];
    private productModelParts: ProductModelPart[];
    private dataParams: HttpParams;

    constructor(
        @Inject(forwardRef(() => MetricService)) private metricService: MetricService,
        @Inject(forwardRef(() => MatDialogRef)) public dialogRef: MatDialogRef<OrganizationAddDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data,
        @Inject(forwardRef(() => ThingDefinitionService)) private thingDefinitionService: ThingDefinitionService,
        @Inject(forwardRef(() => ProductModelService)) private productModelService: ProductModelService,
        @Inject(forwardRef(() => ProductModelPartService)) private productModelPartService: ProductModelPartService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService
    ) {
        this.thingInventoryManagement = this.authenticationService.getThingInventoryManagement();
        this.isThingSelected = !!data.currentThing;
        const selectedElement: DataExportDataElement = data.selectedElement;
        const excludedIds = data.excludedIds || [];
        this.dataParams = data.params;
        if (this.thingInventoryManagement == ThingInventoryManagementType.BY_MODEL) {
            this.initByModel(data, selectedElement, excludedIds);
        } else {
            this.init(data, selectedElement, excludedIds);
        }
    }

    private init(data: any, selectedElement: DataExportDataElement, excludedIds: string[]): void {
        if (this.isThingSelected) {
            this.thingDefinitions = [data.currentThing.thingDefinition];
            this.resourceLoaded = true;
            this.selectedThingDefinitionId = data.currentThing.thingDefinitionId;
            this.currentDataExportMetrics = selectedElement?.dataExportMetrics;
            this.loadMetricsByThingDefinition(this.selectedThingDefinitionId);
        } else {
            this.loadThingDefinitions(this.dataParams).then(tds => {
                this.thingDefinitions = tds.filter(td => !excludedIds.includes(td.id));
                this.resourceLoaded = true;
                if (selectedElement) {
                    this.selectedThingDefinitionId = selectedElement.thingDefinitionId;
                    this.currentDataExportMetrics = selectedElement.dataExportMetrics;
                    this.loadMetricsByThingDefinition(this.selectedThingDefinitionId);
                }
            }).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR));
        }
    }

    private initByModel(data: any, selectedElement: DataExportDataElement, excludedIds: string[]): void {
        if (this.isThingSelected) {
            this.productModelService.getById(data.currentThing.productModelId).then(pm => {
                this.productModels = [pm];
                this.resourceLoaded = true;
                this.selectedProductModelId = data.currentThing.productModelId;
                this.currentDataExportMetrics = selectedElement?.dataExportMetrics;
                this.loadMetricsByProductModel(this.selectedProductModelId);
            }).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR));
        } else {
            this.loadProductModels(this.dataParams).then(productModels => {
                this.productModels = productModels.filter(pm => !excludedIds.includes(pm.id));
                this.resourceLoaded = true;
                if (selectedElement) {
                    this.selectedProductModelId = selectedElement.productModelId;
                    this.currentDataExportMetrics = selectedElement.dataExportMetrics;
                    this.loadMetricsByProductModel(this.selectedProductModelId);
                }
            }).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR));
        }
    }

    thingDefinitionChanged(thingDefinitionId: string): void {
        this.dataExportMetrics = [];
        this.currentDataExportMetrics = null;
        this.selectedDataExportMetrics = null;
        if (thingDefinitionId) {
            this.loadMetricsByThingDefinition(thingDefinitionId);
        }
    }

    productModelChanged(productModelId: string): void {
        this.dataExportMetrics = [];
        this.currentDataExportMetrics = null;
        this.selectedDataExportMetrics = null;
        if (productModelId) {
            this.loadMetricsByProductModel(productModelId);
        }
    }

    private loadMetricsByThingDefinition(thingDefinitionId: string): void {
        this.loadingMetrics = true;
        this.metricService.getMetricsByThingDefinitionId(thingDefinitionId).then(metrics => {
            let dataExportMetrics: DataExportMetric[] = metrics.map(m => {
                return {
                    id: m.id,
                    name: m.name,
                    label: m.label || m.name,
                    group: m.group,
                    productModelPartName: null,
                    productModelPartId: null
                };
            });
            if (this.currentDataExportMetrics?.length) {
                this.selectedDataExportMetrics = dataExportMetrics.filter(m => this.currentDataExportMetrics.some(em => em.id == m.id));
            }
            this.dataExportMetrics = dataExportMetrics;
            this.loadingMetrics = false;
        }).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR));
    }

    private loadMetricsByProductModel(productModelId: string): void {
        this.loadProductModelParts(productModelId).then(parts => {
            let promises = [];
            this.productModelParts = parts;
            this.loadingMetrics = true;
            const productModelThingDefinitionId = this.productModels.find(pm => pm.id == productModelId).thingDefinitionId;
            promises.push(this.metricService.getMetricsByThingDefinitionId(productModelThingDefinitionId).then(metrics => {
                return metrics.map(m => {
                    return {
                        id: m.id,
                        name: m.name,
                        label: m.label || m.name,
                        group: m.group,
                        productModelPartName: null,
                        productModelPartId: null
                    };
                });
            }));
            parts.forEach(part => promises.push(this.metricService.getMetricsByThingDefinitionId(part.thingDefinitionId).then(metrics => {
                return metrics.map(m => {
                    return {
                        id: m.id,
                        name: m.name,
                        label: m.label || m.name,
                        group: m.group,
                        productModelPartName: part.name,
                        productModelPartId: part.id
                    };
                });
            })));
            Promise.all(promises).then((results: DataExportMetric[][]) => {
                let dataExportMetrics: DataExportMetric[] = [];
                results.forEach(metricResult => {
                    dataExportMetrics = dataExportMetrics.concat(metricResult);
                });
                if (this.currentDataExportMetrics?.length) {
                    this.selectedDataExportMetrics = dataExportMetrics.filter(m => this.currentDataExportMetrics.some(em => em.id == m.id && em.productModelPartId == m.productModelPartId));
                }
                this.dataExportMetrics = dataExportMetrics;
                this.loadingMetrics = false;
            }).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR));
        }).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR));
    }

    addMetricData(): void {
        const metrics = this.metricSelector.getSelected();
        let body: DataExportDataElement;
        if (this.thingInventoryManagement == ThingInventoryManagementType.BY_MODEL) {
            const productModelId = this.selectedProductModelId;
            const productModel = this.productModels.find(pm => pm.id == productModelId);
            body = {
                thingDefinitionId: null,
                thingDefinitionName: null,
                dataExportMetrics: metrics,
                metricDisplayedNames: this.getMetricNamesString(metrics),
                productModelId: productModelId,
                productModelName: productModel.name,
                highestSelectedMetricCount: this.getHighestSelectedMetricCount(metrics)
            }
            this.dialogRef.close(body);
        } else {
            const thingDefId = this.selectedThingDefinitionId;
            const thingDefinition = this.thingDefinitions.find(td => td.id == thingDefId);
            body = {
                thingDefinitionId: thingDefId,
                thingDefinitionName: thingDefinition.name,
                dataExportMetrics: metrics,
                metricDisplayedNames: this.getMetricNamesString(metrics),
                productModelId: null,
                productModelName: null,
                highestSelectedMetricCount: this.getHighestSelectedMetricCount(metrics)
            }
        }
        this.dialogRef.close(body);
    }

    private getMetricNamesString(metrics: DataExportMetric[]): string {
        if (this.thingInventoryManagement == ThingInventoryManagementType.BY_MODEL) {
            const partMetricNameMap: { [partName: string]: string[] } = this.getPartMetricNameMap(metrics);
            let result = partMetricNameMap['PRODUCT_MODEL'] ? (partMetricNameMap['PRODUCT_MODEL'].join(', ') + '\n') : '';
            this.productModelParts?.forEach(part => {
                if (partMetricNameMap[part.name]) {
                    result += `[${part.name}] ` + partMetricNameMap[part.name].join(', ') + '\n';
                }
            });
            return result;
        } else {
            return metrics.map(m => m.label || m.name).join(', ');
        }
    }

    private getHighestSelectedMetricCount(metrics: DataExportMetric[]): number {
        if (this.thingInventoryManagement == ThingInventoryManagementType.BY_MODEL) {
            const partMetricCountMap: { [partName: string]: string[] } = this.getPartMetricNameMap(metrics);
            let highestCount: number = partMetricCountMap['PRODUCT_MODEL'] ? partMetricCountMap['PRODUCT_MODEL'].length : 0;
            Object.keys(partMetricCountMap).forEach(key => {
                if (partMetricCountMap[key].length > highestCount) {
                    highestCount = partMetricCountMap[key].length;
                }
            });
            return highestCount;
        } else {
            return metrics.length;
        }
    }

    private getPartMetricNameMap(metrics: DataExportMetric[]): { [partName: string]: string[] } {
        let partMetricNameMap: { [partName: string]: string[] } = {};
        metrics.forEach(m => {
            let partName = m['productModelPartName'];
            if (!partName) {      //product model metric
                partName = 'PRODUCT_MODEL';
            }
            if (partMetricNameMap[partName]) {
                partMetricNameMap[partName].push(m.label || m.name);
            } else {
                partMetricNameMap[partName] = [m.label || m.name];
            }
        });
        return partMetricNameMap;
    }

    isFormValid(): boolean {
        const metrics = this.metricSelector?.getSelected();
        return (this.thingInventoryManagement == ThingInventoryManagementType.BY_MODEL ? this.selectedProductModelId : this.selectedThingDefinitionId) && metrics?.length > 0;
    }

    private loadThingDefinitions(params: HttpParams): Promise<ThingDefinition[]> {
        return this.thingDefinitionService.getThingDefinitionsAssociatedToThings(params).then(thingDefs => {
            return thingDefs;
        });
    }

    private loadProductModels(params: HttpParams): Promise<ProductModel[]> {
        return this.productModelService.getProductModelsAssociatedToThings(params).then(productModels => {
            return productModels;
        });
    }

    private loadProductModelParts(productModelId: string): Promise<ProductModelPart[]> {
        const params = new HttpParams().set('productModelId', productModelId);
        return this.productModelPartService.getRecursivelyAllProductModelParts(0, [], params).then(parts => {
            return parts.filter(part => part.thingDefinitionId);
        });
    }

}