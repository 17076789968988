import { Component, Inject, Input, NgZone, OnInit, PLATFORM_ID, forwardRef } from '@angular/core';
import { GET_DATA_ERROR } from '../../common/constants';
import { Benchmark } from '../../model/benchmark';
import { AuthenticationService } from '../../service/authentication.service';
import { CapitalizePipe, LoaderPipe, LocalizationPipe } from '../../shared/pipe';
import { ErrorUtility } from '../../utility/error-utility';
import { AmChart5Component } from '../amchart5/am-chart5.component';
import { BenchmarkService } from './benchmark.service';

@Component({
    selector: 'benchmark-widget',
    template: require('./benchmark.component.html'),
    styles: [`
        .info-text {
            margin: 10px 10px 10px 10px;
        }
    `],
    providers: [BenchmarkService]
})
export class BenchmarkComponent extends AmChart5Component implements OnInit {

    @Input() title: string;

    @Input() description: string;

    @Input() target: BenchmarkContext = BenchmarkContext.THING;

    @Input() minValue: number;

    @Input() maxValue: number;

    @Input() maxBarNumber: number = 20;

    @Input() valueProperty: string;

    @Input() valueFilter: string;

    @Input() valueLabel: string;

    @Input() unit: string;

    @Input() clusterProperties: string | string[];

    @Input() query: { property: string, predicate: string, value: any }[];

    @Input() height: string = '500px';

    info: string;
    empty: boolean;
    error: string;
    chartId: string;
    hidden: boolean;

    private targetLabel: string;
    private targetsLabel: string;
    private series: any;
    private xAxis: any;
    static nextId = 0;

    constructor(
        @Inject(PLATFORM_ID) platformId: Object,
        @Inject(forwardRef(() => NgZone)) zone: NgZone,
        @Inject(forwardRef(() => BenchmarkService)) private benchmarkService: BenchmarkService,
        @Inject(forwardRef(() => LoaderPipe)) private loaderPipe: LoaderPipe,
        @Inject(forwardRef(() => CapitalizePipe)) private capitalizePipe: CapitalizePipe,
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService) {
        super(platformId, zone);
    }

    ngOnInit(): void {
        this.checkIfHidden();
        this.chartId = `benchmark-diagram-${++BenchmarkComponent.nextId}`;
        this.targetLabel = this.localizationPipe.transform(this.target.toLowerCase());
        this.targetsLabel = this.localizationPipe.transform(`${this.target.toLowerCase()}s`);
    }

    private checkIfHidden(): void {
        if (this.authenticationService.isOrganizationUser() || this.authenticationService.isPartnerUser()) {
            this.hidden = !this.authenticationService.hasFeature('advancedWidgetsForOrgPartner');
        } else {
            this.hidden = !this.authenticationService.hasFeature('advancedWidgetsForCustomer');
        }
    }

    protected getChartId(): string {
        return this.chartId;
    }

    protected initChart(): void {
        // Create chart
        // https://www.amcharts.com/docs/v5/charts/xy-chart/
        let chart = this.root.container.children.push(this.am5xy.XYChart.new(this.root, {
            panX: false,
            panY: false,
            layout: this.root.verticalLayout
        }));

        // Create axes
        // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
        let xAxis = chart.xAxes.push(this.am5xy.CategoryAxis.new(this.root, {
            categoryField: 'from',
            renderer: this.am5xy.AxisRendererX.new(this.root, {}),
            tooltip: this.am5.Tooltip.new(this.root, {
                labelText: '{from} - {to}'
            })
        }));

        xAxis.get('renderer').grid.template.setAll({
            visible: false
        });

        let yAxis = chart.yAxes.push(this.am5xy.ValueAxis.new(this.root, {
            renderer: this.am5xy.AxisRendererY.new(this.root, {})
        }));
        yAxis.get('renderer').grid.template.setAll({
            visible: false
        });

        // Add series
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
        let series = chart.series.push(this.am5xy.ColumnSeries.new(this.root, {
            name: 'Series',
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'value',
            categoryXField: 'from'
        }));

        series.columns.template.setAll({
            tooltipX: this.am5.percent(50),
            tooltipY: this.am5.percent(0)
        });

        series.columns.template.adapters.add('tooltipText', (tooltipText, target) => {
            let tooltipKey = this.localizationPipe.transform('BenchmarkWidget.tooltip', '${type} in this cluster');
            tooltipKey = tooltipKey.replace('${type}', this.capitalizePipe.transform(this.targetLabel));
            tooltipKey += ': {value}';
            if (target.dataItem.dataContext.current) {
                const valueLabel = this.valueLabel || this.valueProperty;
                return `${valueLabel}: {current}\n` + tooltipKey;
            } else {
                return tooltipKey;
            }
        });

        series.columns.template.adapters.add('fill', (fill, target) => {
            if (!target.dataItem.dataContext.current) {
                return this.am5.color('#e0e0e0');
            }
            return fill;
        });

        series.columns.template.adapters.add('stroke', (stroke, target) => {
            if (!target.dataItem.dataContext.current) {
                return this.am5.color('#e0e0e0');
            }
            return stroke;
        });
        let cursor = chart.set('cursor',
            this.am5xy.XYCursor.new(this.root, {
                xAxis: xAxis
            })
        );
        cursor.lineY.set('visible', false);
        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        series.appear();
        chart.appear(1000, 100);

        this.series = series;
        this.xAxis = xAxis;
        this.fetchBenchmarkData();
    }

    private fetchBenchmarkData() {
        let promise;
        try {
            promise = this.benchmarkService.getBenchmark(this.target, this.maxBarNumber, this.valueProperty, this.clusterProperties, this.minValue, this.maxValue, this.query);
        } catch (err) {
            this.zone.run(() => {
                this.error = (err as Error).message;
            });
            return;
        }

        promise.then(data => {
            const [seriesData, position] = this.buildSeriesData(data);
            if (seriesData.reduce((accumulator, currentValue) => accumulator + currentValue.value, 0)) {
                this.xAxis.data.setAll(seriesData);
                this.series.data.setAll(seriesData);
                this.zone.run(() => {
                    if (position != null) {
                        if (position) {
                            let infoText = this.localizationPipe.transform('BenchmarkWidget.ranking', 'Better than ${percentageValue}% of similar ${type}');
                            infoText = infoText.replace('${percentageValue}', Math.round(position).toString());
                            infoText = infoText.replace('${type}', this.targetsLabel);
                            this.info = infoText;
                        } else {
                            let infoText = this.localizationPipe.transform('BenchmarkWidget.worstCluster', 'This ${type} is in the worst performing cluster');
                            infoText = infoText.replace('${type}', this.targetLabel);
                            this.info = infoText;
                        }
                    } else {
                        let infoText = this.localizationPipe.transform('BenchmarkWidget.outOfRangeCluster', '${type} performance out of range');
                        infoText = infoText.replace('${type}', this.capitalizePipe.transform(this.targetLabel));
                        this.info = infoText;
                    }
                });
            } else {
                this.zone.run(() => {
                    this.empty = true;
                    this.info = this.localizationPipe.transform('BenchmarkWidget.noClusters', 'No clusters found');
                });
            }
        }).catch(err => {
            this.zone.run(() => {
                this.error = ErrorUtility.getMessage(err, GET_DATA_ERROR)
            });
        });
    }

    private buildSeriesData(response: Benchmark) {
        let series = [];
        let sum = 0;
        let prevSum = 0;
        response.data.forEach((d, i) => {
            let obj: { [key: string]: any } = {
                value: d.value,
                from: this.computeValue(d.details.from),
                to: this.computeValue(d.details.to),
            };
            series.push(obj);
            if (response.currentIndex != null) {
                sum += d.value as number;
                if (i < response.currentIndex) {
                    prevSum += d.value as number;
                }
            }
        });
        let position = null;
        if (response.currentIndex != null) {
            series[response.currentIndex].current = this.computeValue(response.current);
            position = (prevSum / sum) * 100;
        }
        return [series, position];
    }

    private computeValue(value) {
        if (this.valueFilter) {
            value = this.loaderPipe.transform(value, this.valueFilter, true);
        }
        if (this.unit) {
            value = `${value}${this.unit}`;
        }
        return value;
    }
}

export enum BenchmarkContext {
    THING = 'THING',
    LOCATION = 'LOCATION',
    CUSTOMER = 'CUSTOMER',
    PARTNER = 'PARTNER'
}