import { ComponentRef, Injectable } from '@angular/core';
import { MatSelectionFieldComponent } from '../shared/component/mat-selection-field/mat-selection-field.component';
import { PeriodFieldSelectionStyle, PeriodFieldV2Component } from '../shared/component/period-field/period-field-v2.component';
import { DateRangeName } from './date-range.service';

@Injectable()
export class ExternalComponentUtilsService {

    createPeriodPicker(element: Element, periodV2Settings: PeriodFieldV2Settings, context: any): PeriodFieldV2Component {
        if (element && context?.viewContainerRef) {
            let periodV2Component: ComponentRef<PeriodFieldV2Component> = context.viewContainerRef.createComponent(PeriodFieldV2Component);
            if (periodV2Settings?.id) {
                periodV2Component.instance.id = periodV2Settings.id;
            }
            if (periodV2Settings?.startVariable) {
                periodV2Component.instance.startVariable = periodV2Settings.startVariable;
            }
            if (periodV2Settings?.endVariable) {
                periodV2Component.instance.endVariable = periodV2Settings.endVariable;
            }
            if (periodV2Settings?.periods) {
                periodV2Component.instance.periods = periodV2Settings.periods;
            }
            if (periodV2Settings?.defaultPeriodValue) {
                periodV2Component.instance.defaultPeriodValue = periodV2Settings.defaultPeriodValue;
            }
            if (periodV2Settings?.periodSelectionStyle) {
                periodV2Component.instance.periodSelectionStyle = periodV2Settings.periodSelectionStyle;
            }
            if (periodV2Settings?.maxDaysBack) {
                periodV2Component.instance.maxDaysBack = periodV2Settings.maxDaysBack;
            }
            if (periodV2Settings?.futureDatesSelectable != null) {
                periodV2Component.instance.futureDatesSelectable = periodV2Settings.futureDatesSelectable;
            }
            if (periodV2Settings?.periodSelectionEnabled != null) {
                periodV2Component.instance.periodSelectionEnabled = periodV2Settings.periodSelectionEnabled;
            }
            if (periodV2Settings?.onChange) {
                periodV2Component.instance.onChange = periodV2Settings.onChange;
            }
            if (periodV2Settings?.preserveSelection != null) {
                periodV2Component.instance.preserveSelection = periodV2Settings.preserveSelection;
            }
            const componentElement: Element = periodV2Component.location.nativeElement;
            element.appendChild(componentElement);
            return periodV2Component.instance;
        } else {
            console.error("Invalid element or missing context");
            return null;
        }
    }

    createSelectionField(element: Element, settings: SelectionFieldSettings, context: any): MatSelectionFieldComponent {
        if (element && context?.viewContainerRef) {
            let matSelectionField: ComponentRef<MatSelectionFieldComponent> = context.viewContainerRef.createComponent(MatSelectionFieldComponent);
            if (settings?.label) {
                matSelectionField.instance.label = settings.label;
            }
            if (settings?.description) {
                matSelectionField.instance.description = settings.description;
            }
            if (settings?.placeholder) {
                matSelectionField.instance.placeholder = settings.placeholder;
            }
            if (settings?.defaultValue) {
                matSelectionField.instance.defaultValue = settings.defaultValue;
            }
            if (settings?.values) {
                matSelectionField.instance.values = settings.values;
            }
            if (settings?.multiple) {
                matSelectionField.instance.multiple = settings.multiple;
            }
            if (settings?.mandatory) {
                matSelectionField.instance.mandatory = settings.mandatory;
            }
            if (settings?.clearable) {
                matSelectionField.instance.clearable = settings.clearable;
            }
            if (settings?.callback) {
                matSelectionField.instance.callback = settings.callback;
            }
            const componentElement: Element = matSelectionField.location.nativeElement;
            element.appendChild(componentElement);
            return matSelectionField.instance;
        } else {
            console.error("Invalid element or missing context");
            return null;
        }
    }
}

export interface PeriodFieldV2Settings {
    id: string;
    startVariable: string;
    endVariable: string;
    periods: string[];
    defaultPeriodValue: DateRangeName;
    periodSelectionStyle: PeriodFieldSelectionStyle;
    maxDaysBack: number;
    futureDatesSelectable: boolean;
    periodSelectionEnabled: boolean;
    onChange: Function;
    preserveSelection: boolean;
}

export interface SelectionFieldSettings {
    label: string;
    description: string;
    placeholder: string;
    defaultValue: string | string[];
    values: { label: string, value: string }[];
    multiple: boolean;
    mandatory: boolean;
    clearable: boolean;
    callback: Function;
}