import { AfterContentInit, ContentChildren, Directive, Inject, Input, OnInit, QueryList, forwardRef } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { PropertyInfo } from "../../common/properties";
import { WorkSessionService } from "../../dashboard-area/shared/work-session.service";
import { WorkSessionDetailsPageDialogComponent } from "../../dashboard-area/work-session-details/work-session-details-page-dialog.component";
import { WorkSession, WorkSessionDefinition } from "../../model";
import { AbstractExportContextService } from "../../service/abstract-export-context.service";
import { AppService } from "../../service/app.service";
import { AuthenticationService } from "../../service/authentication.service";
import { FieldService } from "../../service/field.service";
import { NavigationService } from "../../service/navigation.service";
import { ButtonActionValue } from "../../shared/custom-table";
import { AdvancedSearchLayoutType, ClickOnRowBehaviour, ColumnComponentDisplayMode, ListWidgetV2Component } from "../../shared/list-widget-v2/list-widget-v2.components";
import { SearchTargetType } from "../../shared/search-field/search-field.component";
import { COMPONENT_DEFINITION_REF } from "../../shared/utility/component-definition-token";
import { DetailsModeType } from "../shared/alert-work-session-list";
import { AbstractWorkSessionListWidgetV2Service } from "./abstract-work-session-list-widget-v2.service";

@Directive()
export abstract class AbstractWorkSessionListWidgetV2Component extends ListWidgetV2Component<WorkSession> implements OnInit, AfterContentInit {

    @Input() searchFields: string[] = ["name", "title", "description", "thing.name", "thingDefinition.name"];

    @Input() enableActions: boolean = true;

    @Input() detailsMode: DetailsModeType = DetailsModeType.POPUP;

    @Input() includeSubThingsWorkSessions: boolean;

    @ContentChildren(COMPONENT_DEFINITION_REF) private columnComponents: QueryList<any>;

    protected defaultProperties: { [name: string]: PropertyInfo } = {
        name: { label: 'workSessionDefinitionNameProperty', path: 'name', defaultFilter: null, defaultSorting: null },
        title: { label: 'workSessionDefinitionTitleProperty', path: 'title', defaultFilter: null, defaultSorting: null },
        description: { label: 'workSessionDefinitionDescriptionProperty', path: 'description', defaultFilter: null, defaultSorting: null },
        date: { label: 'workSessionDateProperty', path: 'date', defaultFilter: null, defaultSorting: null },
        duration: { label: 'workSessionDurationProperty', path: 'duration', defaultFilter: null, defaultSorting: null }
    };
    protected workSessionDefinitions: WorkSessionDefinition[] = [];
    protected searchTargetType: SearchTargetType;
    protected localStorageKey: string;

    initCompleted: boolean;

    constructor(
        @Inject(forwardRef(() => AbstractWorkSessionListWidgetV2Service)) private abstractWorkSessionListWidgetV2Service: AbstractWorkSessionListWidgetV2Service<WorkSession>,
        @Inject(forwardRef(() => NavigationService)) protected navigationService: NavigationService,
        @Inject(forwardRef(() => AppService)) appService: AppService,
        @Inject(forwardRef(() => WorkSessionService)) protected workSessionService: WorkSessionService,
        @Inject(forwardRef(() => AuthenticationService)) authenticationService: AuthenticationService,
        @Inject(forwardRef(() => AbstractExportContextService)) exportService: AbstractExportContextService,
        @Inject(forwardRef(() => MatDialog)) protected dialog: MatDialog,
        @Inject(forwardRef(() => FieldService)) fieldService: FieldService
    ) {
        super(appService, authenticationService, exportService, null, dialog, null, fieldService);
    }

    protected abstract init(): void;
    protected abstract getWorkSessionList(): void;
    protected abstract goToWorkSessionDetails(id: string): void;
    protected abstract deleteWorkSession(id: string): void;

    ngOnInit() {
        this.init();
        this.checkIsMobile();
        if (this.exportEnabled) {
            this.subscribeToExportServices();
        }
        this.subscribeToPeriodField();
    }

    ngAfterContentInit(): void {
        this.initVisibleColumns();
        this.workSessionService.getWorkSessionDefinitions(true).then(ws => {
            this.workSessionDefinitions = ws;
            this.initCompleted = true;
            const storedFieldsValues = localStorage.getItem(this.queryFieldRef || this.localStorageKey);
            const savedFieldsValues = storedFieldsValues ? JSON.parse(storedFieldsValues) : null;
            if (!savedFieldsValues && !this.query && !this.queryFieldRef && this.advancedSearchLayout != AdvancedSearchLayoutType.POPUP && !this.periodRef) {
                this.getWorkSessionList();
            }
        });
    }

    protected initVisibleColumns(): void {
        this.columnComponentsArray = this.abstractWorkSessionListWidgetV2Service.getColumnComponentsArray(this.columnComponents.toArray(), this.defaultProperties);
        this.displayedColumns = this.abstractWorkSessionListWidgetV2Service.getVisibleColumns(this.columnComponentsArray, this.defaultProperties, 'WorkSession', this.id);
        this.descriptions = this.abstractWorkSessionListWidgetV2Service.getColumnDescriptions(this.columnComponents.toArray());
        this.sort = [];
        this.editTableColumnsEnabled = !!this.id && this.columnComponentsArray.filter(c => c.displayMode != ColumnComponentDisplayMode.ALWAYS_HIDDEN).length > 1;
    }

    export(): void {
        const params = this.abstractWorkSessionListWidgetV2Service.getParams(this.searchFields, this.advancedSearchBody, true, this.includeSubThingsWorkSessions);
        this.abstractWorkSessionListWidgetV2Service.downloadCSV(params, this.exportService.resolveExportFileNamePlaceholders(this.exportFileName, this.advancedSearchBody?.startTimestamp, this.advancedSearchBody?.endTimestamp));
    }

    goToDetail(workSession: WorkSession): void {
        if (this.clickOnRowBehaviour == ClickOnRowBehaviour.OPEN_CONTEXT_OBJECT) {
            this.navigationService.goToThingDetailPage(workSession.thing?.id);
        } else if (workSession['showDetailsButton']) {
            this.goToWorkSessionDetails(workSession.id);
        }
    }

    execButtonAction(actionValue: ButtonActionValue): void {
        if (actionValue.action == 'showDetails') {
            this.goToWorkSessionDetails(actionValue.value);
        } else if (actionValue.action == 'delete') {
            this.deleteWorkSession(actionValue.value);
        }
    }

    protected openDialog(id: string, isHistorical: boolean): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.minWidth = '25%';
        dialogConfig.data = { id: id, isHistorical: isHistorical };
        this.dialog.open(WorkSessionDetailsPageDialogComponent, dialogConfig);
    }
}