import { Component, Inject, forwardRef } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import * as _ from "lodash";
import { CustomPropertyDefinition } from "../../model";
import { ObjectArrayService } from "./object-array.service";

@Component({
    selector: 'object-array-dialog',
    template: require('./object-array-dialog.component.html'),
    providers: [ObjectArrayService]
})
export class ObjectArrayDialogComponent {

    error: string;
    propertyDefinitions: CustomPropertyDefinition[];
    propertiesFormGroup: FormGroup;
    values: { [key: string]: any };

    constructor(
        @Inject(forwardRef(() => MatDialogRef)) public dialogRef: MatDialogRef<ObjectArrayDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data,
        @Inject(forwardRef(() => ObjectArrayService)) private objectArrayService: ObjectArrayService
    ) {
        const dialogData = _.cloneDeep(data);
        this.propertyDefinitions = dialogData.definitions || [];
        this.values = dialogData.values;
        this.propertiesFormGroup = new FormGroup({});
    }

    performAdd(): void {
        this.dialogRef.close(this.propertiesFormGroup.getRawValue());
    }

    isVisible(definition: CustomPropertyDefinition): boolean {
        const item = this.propertiesFormGroup.getRawValue();
        return this.objectArrayService.isPropertyDefintionVisible(definition, item);
    }
}