import { HttpParams } from '@angular/common/http';
import { Inject, Injectable, forwardRef } from '@angular/core';
import * as L from 'leaflet';
import { firstValueFrom } from 'rxjs';
import { BULK_UPDATES_AVAILABILITY, LOCATION_BY_ID, THING_BY_ID, USER_THING_V2 } from '../../common/endpoints';
import { AlertService } from '../../dashboard-area/shared/alert.service';
import { Location, PagedList, Thing, ThingAlertStatus } from '../../model';
import { BulkUpdatesAvailability } from '../../model/bulk-updates-availability';
import { HttpService } from '../../service/http.service';
import { UserLocationService } from '../../service/user-location.service';
import { AbstractContextService } from '../../shared/class/abstract-context-service.class';
import { AbstractThingContextService } from '../../shared/class/abstract-thing-context-service.class';

@Injectable()
export class MapService {

    constructor(@Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => UserLocationService)) private userLocationService: UserLocationService,
        @Inject(forwardRef(() => AlertService)) private alertService: AlertService,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
        @Inject(forwardRef(() => AbstractThingContextService)) private thingContextService: AbstractThingContextService
    ) { }


    getAllThings(advancedSearchBody?: any, searchFields?: string[]): Promise<ThingForMap[]> {
        let thingList: ThingForMap[] = [];
        return this.getThings(0, advancedSearchBody, searchFields).then(pagedList => {
            thingList = thingList.concat(pagedList.content.map(t => {
                const thingForMap = t as ThingForMap;
                this.mapObjects(thingForMap);
                return thingForMap;
            }));
            let promises = [];
            for (let i = 1; i < pagedList.totalPages; i++) {
                promises.push(this.getThings(i, advancedSearchBody, searchFields));
            }
            return Promise.all(promises).then(results => {
                results.forEach(res => {
                    thingList = thingList.concat(res.content.map(t => {
                        const thingForMap = t as ThingForMap;
                        this.mapObjects(thingForMap);
                        return thingForMap;
                    }));
                });
                return thingList;
            });
        });
    }

    async *getThingsInChunks(chunkSize: number = 5, advancedSearchBody?: any, searchFields?: string[]): AsyncIterable<ThingForMap[]> {
        let totalPages: number;
        yield await this.getThings(0, advancedSearchBody, searchFields).then(pagedList => {
            totalPages = pagedList.totalPages
            return pagedList.content.map(t => {
                const thingForMap = t as ThingForMap;
                this.mapObjects(thingForMap);
                return thingForMap;
            });
        });
        const chunks = Math.ceil(totalPages / chunkSize);
        for (let i = 0; i < chunks; i++) {
            const start = 1 + i * chunkSize;
            const end = Math.min((i + 1) * chunkSize, totalPages - 1);
            let promises = [];
            for (let j = start; j <= end; j++) {
                promises.push(this.getThings(j, advancedSearchBody, searchFields));
            }
            yield await Promise.all(promises).then(results => {
                let thingList = [];
                results.forEach(res => {
                    thingList = thingList.concat(
                        res.content.map(t => {
                            const thingForMap = t as ThingForMap;
                            this.mapObjects(thingForMap);
                            return thingForMap;
                        }));
                });
                return thingList;
            });
        }
    }

    private getThings(page: number, advancedSearchBody: any, searchFields: any): Promise<PagedList<Thing>> {
        let params = this.buildParams(advancedSearchBody, searchFields, page);
        params = this.buildContextParams(params);
        params = params.set('sort', '_id');
        return firstValueFrom(this.httpService.get<PagedList<Thing>>(USER_THING_V2, params));
    }


    updateThing(thing: Thing): Promise<Thing> {
        return firstValueFrom(this.httpService.put<Thing>(THING_BY_ID.replace('{id}', thing.id), thing));
    }

    getAllLocations(advancedSearchBody?: any, searchFields?: string[]): Promise<LocationForMap[]> {
        let params = this.buildParams(advancedSearchBody, searchFields);
        const customerId = this.contextService.getCurrentCustomer() ? this.contextService.getCurrentCustomer().id : null;
        return this.userLocationService.getRecursivelyAllLocations(0, [], customerId, params)
            .then(locations => {
                return locations.map(l => {
                    const locationForMap = l as LocationForMap;
                    this.mapObjects(locationForMap);
                    return locationForMap;
                });
            });
    }

    updateLocation(location: Location): Promise<Location> {
        return firstValueFrom(this.httpService.put<Location>(LOCATION_BY_ID.replace('{id}', location.id), location));
    }

    getAllAlerts(): Promise<ThingAlertStatus[]> {
        return this.alertService.getRecursivelyAllAlerts();
    }

    getParentThingCandidates(things: Thing[], selectedThings: Thing[]): Thing[] {
        if (selectedThings.length) {
            const thingDefId = selectedThings[0].thingDefinitionId;
            const locationId = selectedThings[0].locationId;

            if (selectedThings[0].thingDefinition?.parentThingBulkSetEnabled &&
                selectedThings.every(t => t.thingDefinitionId == thingDefId) &&
                selectedThings.every(t => t.locationId == locationId)) {
                const parentThingId = selectedThings[0].thingDefinition?.parentThingDefinitionId;
                if (parentThingId) {
                    return things.filter(t => t.locationId == locationId && t.thingDefinitionId == parentThingId);
                } else {
                    return []
                }
            }
        }
        return [];
    }

    getBulkUpdateAvailability(thingDefinitionIds: Set<string>): Promise<BulkUpdatesAvailability> {
        return firstValueFrom(this.httpService.post<BulkUpdatesAvailability>(BULK_UPDATES_AVAILABILITY, Array.from(thingDefinitionIds)));
    }

    buildParams(advancedSearchBody: any, searchFields: string[], page?: number): HttpParams {
        let params = new HttpParams();
        if (advancedSearchBody) {
            if (advancedSearchBody.key) {
                params = params.set('searchText', "*" + advancedSearchBody.key + "*");
                searchFields.forEach(field => params = params.append('searchField', field));
            }
            if (advancedSearchBody.customer) {
                params = params.set('customerId', advancedSearchBody.customer);
            }
            if (advancedSearchBody.thingDefinitions && advancedSearchBody.thingDefinitions.length) {
                advancedSearchBody.thingDefinitions.forEach(id => params = params.append('thingDefinitionId', id));
            }
            if (advancedSearchBody.tags && advancedSearchBody.tags.length) {
                advancedSearchBody.tags.forEach(tag => params = params.append('tagId', tag));
            }
            if (advancedSearchBody.name) {
                params = params.set('name', advancedSearchBody.name);
            }
            if (advancedSearchBody.serialNumber) {
                params = params.set('serialNumber', advancedSearchBody.serialNumber);
            }
            if (advancedSearchBody['customer.name']) {
                params = params.set('customer.name', advancedSearchBody['customer.name']);
            }
            if (advancedSearchBody['customer.code']) {
                params = params.set('customer.code', advancedSearchBody['customer.code']);
            }
            if (advancedSearchBody['location.name']) {
                params = params.set('location.name', advancedSearchBody['location.name']);
            }
            if (advancedSearchBody['thingDefinition.name']) {
                params = params.set('thingDefinition.name', advancedSearchBody['thingDefinition.name']);
            }
            if (advancedSearchBody['partner.name']) {
                params = params.set('partner.name', advancedSearchBody['partner.name']);
            }
            if (advancedSearchBody.serviceLevels && advancedSearchBody.serviceLevels.length) {
                advancedSearchBody.serviceLevels.forEach(id => params = params.append('serviceLevelId', id));
            }
            if (advancedSearchBody['serviceLevel.name']) {
                params = params.set('serviceLevel.name', advancedSearchBody['serviceLevel.name']);
            }
            if (advancedSearchBody['connectionStatus']) {
                params = params.set('connectionStatus', advancedSearchBody['connectionStatus']);
            }
            if (advancedSearchBody['connectionStatusLastUpdateTimestamp']) {
                params = params.set('connectionStatusLastUpdateTimestamp', advancedSearchBody['connectionStatusLastUpdateTimestamp']);
            }
            if (advancedSearchBody['cloudStatus']) {
                params = params.set('cloudStatus', advancedSearchBody['cloudStatus']);
            }
            if (advancedSearchBody['cloudStatusLastUpdateTimestamp']) {
                params = params.set('cloudStatusLastUpdateTimestamp', advancedSearchBody['cloudStatusLastUpdateTimestamp']);
            }
            if (advancedSearchBody['gpsPosition']) {
                params = params.set('gpsPosition', advancedSearchBody['gpsPosition']);
            }
            if (advancedSearchBody['location.gpsPosition']) {
                params = params.set('location.gpsPosition', advancedSearchBody['location.gpsPosition']);
            }
            if (advancedSearchBody.productModels && advancedSearchBody.productModels.length) {
                advancedSearchBody.productModels.forEach(id => params = params.append('productModelId', id));
            }
            if (advancedSearchBody['productModel.name']) {
                params = params.set('productModel.name', advancedSearchBody['productModel.name']);
            }
            if (advancedSearchBody['customer.countries'] && advancedSearchBody['customer.countries'].length) {
                params = params.append('customer.country', 'eq;' + advancedSearchBody['customer.countries'].join(','));
            }
            const properties = Object.keys(advancedSearchBody).filter(key => key.includes('properties.'));
            properties.forEach(property => params = params.set(property, advancedSearchBody[property]));
        }
        if (!page) {
            page = 0;
        }
        params = params.set('page', `${page}`);
        params = params.set('size', `${200}`);
        return params;
    }

    buildContextParams(params: HttpParams): HttpParams {
        if (this.thingContextService.getCurrentThing()) {
            params = params.set('parentThingId', this.thingContextService.getCurrentThing().id);
        } else if (this.contextService.getCurrentLocation()) {
            params = params.set('locationId', this.contextService.getCurrentLocation().id);
        } else if (this.contextService.getCurrentCustomer()) {
            params = params.set('customerId', this.contextService.getCurrentCustomer().id);
        } else if (this.contextService.getCurrentPartner()) {
            params = params.set('partnerId', this.contextService.getCurrentPartner().id);
        }
        return params;
    }

    private isValidLatLng(latlng: string): boolean {
        if (latlng) {
            return /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/.test(latlng);
        }
        return false;
    }

    private mapObjects(obj): void {
        let gpsPosition = obj.gpsPosition || obj.location?.gpsPosition;
        if (this.isValidLatLng(gpsPosition)) {
            const coords = gpsPosition.split(',');
            obj.latLng = L.latLng(parseFloat(coords[0]), parseFloat(coords[1]));
        } else {
            obj.latLng = null;
        }
    }
}

export class ThingForMap extends Thing {
    latLng: L.latLng;
    rank: number;
    selected: boolean;
}

export class LocationForMap extends Location {
    latLng: L.latLng;
    rank: number;
    selected: boolean;
}
