import { Component, ContentChildren, forwardRef, QueryList } from '@angular/core';
import { CompositePartComponent, MetricDetailComponent, PropertyComponent } from '../../../shared/component';
import { COMPONENT_DEFINITION_REF } from '../../../shared/utility/component-definition-token';

@Component({
    selector: 'selection-input',
    template: '',
    providers: [{ provide: COMPONENT_DEFINITION_REF, useExisting: forwardRef(() => SelectionInputComponent) }]
})
export class SelectionInputComponent {

    @ContentChildren(COMPONENT_DEFINITION_REF) columnComponents: QueryList<MetricDetailComponent | CompositePartComponent | PropertyComponent>;

}

export class SelectionInputDialogData {
    columnComponents: QueryList<MetricDetailComponent | CompositePartComponent | PropertyComponent>;
    queryId: string;
    advancedSearchBody: any;
}