import { HttpParams } from '@angular/common/http';
import { Inject, Injectable, forwardRef } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { USER_ME_SUBSCRIPTION_SET, USER_ME_SUBSCRIPTION_SUMMARY, USER_ME_VIRTUAL_SUBSCRIPTION_SETS } from '../common/endpoints';
import { DigitalServiceConfigurationType, PagedList, SubscriptionSet, SubscriptionSetStatus, SubscriptionSummary, VirtualSubscriptionSet } from '../model';
import { HttpService } from './http.service';

@Injectable()
export class SubscriptionSetService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService
    ) { }

    getSubscriptionSet(customerId?: string, partnerId?: string): Promise<SubscriptionSet> {
        let params = new HttpParams();
        if (customerId) {
            params = params.set('customerId', customerId);
        } else if (partnerId) {
            params = params.set('partnerId', partnerId);
        }
        return firstValueFrom(this.httpService.get<SubscriptionSet>(USER_ME_SUBSCRIPTION_SET, params));
    }

    getPagedVirualSubscriptionSets(type: DigitalServiceConfigurationType, page: number, size: number, sort: string[], searchText: string, status: SubscriptionSetStatus): Promise<PagedList<VirtualSubscriptionSet>> {
        let params = new HttpParams().set('type', type);
        if (sort?.length) {
            params = params.set('sort', sort.join(','));
        } else {
            params = params.set('sort', 'activationTimestamp, asc');
        }
        params = params.set('page', page + '');
        params = params.set('size', size + '');
        if (searchText) {
            params = params.set('searchText', searchText);
        }
        if (status) {
            params = params.set('status', status);
        }
        return firstValueFrom(this.httpService.get<PagedList<VirtualSubscriptionSet>>(USER_ME_VIRTUAL_SUBSCRIPTION_SETS, params));
    }

    getSubscriptionSummary(type: DigitalServiceConfigurationType): Promise<SubscriptionSummary> {
        let params = new HttpParams().set('type', type);
        return firstValueFrom(this.httpService.get<SubscriptionSummary>(USER_ME_SUBSCRIPTION_SUMMARY, params));
    }
}