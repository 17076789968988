import { Component, forwardRef, Inject, QueryList, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from '../../../service/app.service';
import { FieldService } from '../../../service/field.service';
import { CustomerAdvancedSearchAddPropertiesDialog } from '../../advanced-search/customer-advanced-search/customer-advanced-search-add-properties-dialog.component';
import { CustomerAdvancedSearchComponent } from '../../advanced-search/customer-advanced-search/customer-advanced-search.component';
import { AbstractSearchFieldComponent } from '../abstract-search-field.component';
import { SearchFieldService } from '../search-field.service';
import { CustomersPropertySearchInputComponent } from './customers-property-search-input.component';

@Component({
    selector: 'customers-search-field',
    template: require('./customers-search-field.component.html'),
    styles: [require('../search-field.component.css')],
})
export class CustomersSearchFieldComponent extends AbstractSearchFieldComponent {

    @ViewChild(CustomerAdvancedSearchComponent) customerAdvancedSearch: CustomerAdvancedSearchComponent;

    @ViewChild(CustomerAdvancedSearchAddPropertiesDialog) addPropertiesDialog: CustomerAdvancedSearchAddPropertiesDialog;

    @ViewChildren(CustomersPropertySearchInputComponent) private customersPropertySearchInputs: QueryList<CustomersPropertySearchInputComponent>;

    constructor(
        @Inject(forwardRef(() => FieldService)) fieldService: FieldService,
        @Inject(forwardRef(() => SearchFieldService)) searchFieldService: SearchFieldService,
        @Inject(forwardRef(() => MatDialog)) matDialog: MatDialog,
        @Inject(forwardRef(() => ViewContainerRef)) vcRef: ViewContainerRef,
        @Inject(forwardRef(() => AppService)) appService: AppService
    ) {
        super(fieldService, searchFieldService, matDialog, vcRef, appService);
    }

    openAddPropertiesDialog(advancedSearchProperties: { name: string, label: string }[]): void {
        this.close();
        this.advancedSearchProperties = advancedSearchProperties;
        setTimeout(() => this.addPropertiesDialog.open(), 400);
    }

    closeAddPropertiesDialog(updatedSearchFields: string[]): void {
        if (updatedSearchFields) {
            this.customerAdvancedSearch.updateSearchFields(updatedSearchFields);
        }
        setTimeout(() => this.open(), 400);
    }

    performClear(): void {
        this.customerAdvancedSearch?.clearAll();
        this.clearInputs();
    }

    protected updateAdvancedSearch(fieldsToSaveBody: any): void {
        this.customerAdvancedSearch?.updateLocalStorage(fieldsToSaveBody);
        this.customerAdvancedSearch?.initAllConfigurations();
    }

    protected getEncodedRawValues(): void {
        let body: any = {};
        this.customersPropertySearchInputs?.forEach(property => Object.assign(body, property.getFormValue(true)));
        return body;
    }

    protected getRawValues() {
        let body: any = {};
        this.customersPropertySearchInputs?.forEach(property => Object.assign(body, property.getFormValue(false)));
        return body;
    }

    protected refreshInputConfigurations(): void {
        this.customersPropertySearchInputs.forEach(input => {
            input.refreshConfiguration();
        });
    }

}