import { AfterViewInit, Component, ContentChildren, Input, OnInit, QueryList } from '@angular/core';
import { CompositePartComponent, MetricDetailComponent, PropertyComponent } from '../../shared/component';
import { COMPONENT_DEFINITION_REF } from '../../shared/utility/component-definition-token';
import { SearchInputComponent, SearchInputType } from './search-input/search-input.component';
import { SelectionInputComponent } from './selection-input/selection-input.component';

@Component({
    selector: 'search-field',
    template: require('./search-field.component.html')
})
export class SearchFieldComponent implements OnInit, AfterViewInit {

    @Input() id: string;

    @Input() searchTarget: SearchTargetType = SearchTargetType.THINGS;

    @Input() searchKeyEnabled: boolean;

    @Input() advancedSearchEnabled: boolean;

    @Input() label: string = '';

    @Input() layout: SearchFieldLayoutType = SearchFieldLayoutType.KEY_FIELD_AND_FILTER_BTN;

    @Input() query: { property: string, predicate: string, value: any }[];

    @Input() rangeSelectionEnabled: boolean = true;

    @Input() periodFilterEnabled: boolean = true;

    @ContentChildren(COMPONENT_DEFINITION_REF) private inputComponents: QueryList<SearchInputComponent | SelectionInputComponent>;

    isInvalidConfiguration: boolean;
    inputs: SearchFieldInput[];
    init: boolean = false;

    ngOnInit(): void {
        if (!this.id) {
            console.error("Invalid configuration. Must provide an ID");
            this.isInvalidConfiguration = true;
        }
        this.id = this.id;
    }

    ngAfterViewInit(): void {
        this.inputs = this.filterInputs();
        setTimeout(() => this.init = true);
    }

    private filterInputs(): SearchFieldInput[] {
        let results: SearchFieldInput[] = [];
        this.inputComponents?.toArray().forEach(component => {
            if (component instanceof SearchInputComponent && (component.type == SearchInputType.PROPERTY || !results.some(res => res.property == component.type))) {
                results.push({ type: this.getSearchFieldInputType(component.type), property: component.property, columnComponents: null });
            } else if (component instanceof SelectionInputComponent) {
                results.push({ type: SearchFieldInputType.SELECTION_INPUT, property: null, columnComponents: component.columnComponents });
            }
        });
        return results.length ? results : [{ type: SearchFieldInputType.CLEAR, property: null, columnComponents: null }, { type: SearchFieldInputType.KEY, property: null, columnComponents: null }, { type: SearchFieldInputType.ADVANCED, property: null, columnComponents: null }];
    }

    private getSearchFieldInputType(type: SearchInputType): SearchFieldInputType {
        switch (type) {
            case SearchInputType.ADVANCED:
                return SearchFieldInputType.ADVANCED;
            case SearchInputType.KEY:
                return SearchFieldInputType.KEY;
            case SearchInputType.PROPERTY:
                return SearchFieldInputType.PROPERTY;
            case SearchInputType.CLEAR:
                return SearchFieldInputType.CLEAR;
            default: return null;
        }
    }
}

export enum SearchTargetType {
    THINGS = "THINGS",
    LOCATIONS = "LOCATIONS",
    CUSTOMERS = "CUSTOMERS",
    PARTNERS = "PARTNERS",
    ACTIONS = "ACTIONS",
    ACTIVE_ALERTS = "ACTIVE_ALERTS",
    HISTORICAL_ALERTS = "HISTORICAL_ALERTS",
    ACTIVE_EVENTS = "ACTIVE_EVENTS",
    HISTORICAL_EVENTS = "HISTORICAL_EVENTS",
    ACTIVE_WORK_SESSIONS = "ACTIVE_WORK_SESSIONS",
    HISTORICAL_WORK_SESSIONS = "HISTORICAL_WORK_SESSIONS",
    THING_CONNECTION_TOKENS = "THING_CONNECTION_TOKENS"
}

export enum SearchFieldLayoutType {
    KEY_FIELD = "KEY_FIELD",
    FILTER_BTN = "FILTER_BTN",
    KEY_FIELD_AND_FILTER_BTN = "KEY_FIELD_AND_FILTER_BTN",
    FILTER_BTN_AND_KEY_FIELD = "FILTER_BTN_AND_KEY_FIELD"
}

export class SearchFieldInput {
    type: SearchFieldInputType;
    property: string;
    columnComponents: QueryList<MetricDetailComponent | CompositePartComponent | PropertyComponent>;
}

export enum SearchFieldInputType {
    ADVANCED = "ADVANCED",
    KEY = "KEY",
    PROPERTY = "PROPERTY",
    CLEAR = "CLEAR",
    SELECTION_INPUT = "SELECTION_INPUT"
}