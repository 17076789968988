import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'mat-selection-field',
    template: require('./mat-selection-field.component.html'),
})
export class MatSelectionFieldComponent implements OnInit {

    @Input() label: string;

    @Input() description: string;

    @Input() placeholder: string;

    @Input() defaultValue: string | string[];

    @Input() values: { label: string, value: string }[];

    @Input() multiple: boolean;

    @Input() mandatory: boolean;

    @Input() clearable: boolean;

    @Input() callback: Function;

    protected selectedValue: string | string[];
    protected matOptions: { label: string, value: string }[];

    constructor() { }

    ngOnInit(): void {
        this.matOptions = this.values;
        this.selectedValue = this.validateValue(this.defaultValue);
    }

    openedChange(opened: boolean): void {
        if (!opened && this.callback) {
            this.callback(this.selectedValue);
        }
    }

    setSelection(value: string | string[]): void {
        this.selectedValue = this.validateValue(value);
    }

    setSelectableValues(newValues: { label: string, value: string }[]): void {
        this.matOptions = newValues;
        this.selectedValue = this.validateValue(this.selectedValue);
    }

    getSelectedValue(): string | string[] {
        return this.selectedValue;
    }

    private validateValue(newValue): string | string[] {
        if (newValue && this.matOptions) {
            const validValues = this.matOptions.map(v => { return v.value });
            if (newValue instanceof Array) {
                if (!this.multiple) {
                    return null;
                } else {
                    return newValue.filter(v => validValues.includes(v));
                }
            } else {
                if (this.multiple) {
                    return null;
                } else {
                    return validValues.includes(newValue) ? newValue : null;
                }
            }
        }
        return null;
    }

}