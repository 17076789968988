import { HttpParams } from "@angular/common/http";
import { forwardRef, Inject, Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { PRODUCT_MODEL_SPARE_PART_DEFINITION_REFERENCES } from "../common/endpoints";
import { PagedList, ProductModelSparePartDefinitionReference } from "../model";
import { AuthenticationService } from "./authentication.service";
import { HttpService } from "./http.service";

@Injectable()
export class ProductModelSparePartDefinitionReferenceService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService
    ) { }

    getRecursivelyAllProductModelSparePartDefinitionReferences(page?: number, references?: ProductModelSparePartDefinitionReference[], params?: HttpParams, sort?: string[]): Promise<ProductModelSparePartDefinitionReference[]> {
        if (!page) {
            page = 0;
        }
        if (!references) {
            references = [];
        }
        if (!sort) {
            sort = ['productModel.name', 'asc'];
        }
        return this.getPagedProductModelSparePartDefinitionReferences(page, 200, sort, params)
            .then(pagedModels => {
                references = references.concat(pagedModels.content);
                if (pagedModels.last) {
                    return references;
                } else {
                    return this.getRecursivelyAllProductModelSparePartDefinitionReferences(++page, references, params, sort);
                }
            });
    }

    private getPagedProductModelSparePartDefinitionReferences(page: number, size: number, sort: string[], params?: HttpParams): Promise<PagedList<ProductModelSparePartDefinitionReference>> {
        if (!params) {
            params = new HttpParams();
        }
        params = params.set('page', page + '');
        params = params.set('size', size + '');
        params = params.set('sort', sort.join(','));
        params = params.set('language', this.authenticationService.getUser()?.language || navigator.language);
        return firstValueFrom(this.httpService.get<PagedList<ProductModelSparePartDefinitionReference>>(PRODUCT_MODEL_SPARE_PART_DEFINITION_REFERENCES, params));
    }

    getCatalogProductModelSparePartDefinitionReferencePagedList(page: number, size: number, sort: string[], categoryId: string, productModelId: string, searchString: string, productModelPartId: string): Promise<PagedList<ProductModelSparePartDefinitionReference>> {
        let params = new HttpParams();
        if (sort?.length) {
            params = params.set('sort', sort.join(','));
        } else {
            params = params.set('sort', 'sparePartDefinition.name, asc');
        }
        params = params.set('page', page + '');
        params = params.set('size', size + '');
        if (categoryId) {
            params = params.set('categoryId', categoryId);
            params = params.set('includeCategoryDescendants', true);
        }
        if (productModelId) {
            params = params.set('productModelId', productModelId);
            if (!productModelPartId) {
                params = params.set('productModelOnly', true);
            }
        }
        if (productModelPartId) {
            params = params.set('productModelPartId', productModelPartId);
        }
        if (searchString) {
            params = params.set('searchText', "*" + searchString + "*");
        }
        if (!productModelId && !productModelPartId) {
            params = params.set('distinctBySparePartDefinitionId', true);
        }
        params = params.set('language', this.authenticationService.getUser()?.language || navigator.language);
        return firstValueFrom(this.httpService.get<PagedList<ProductModelSparePartDefinitionReference>>(PRODUCT_MODEL_SPARE_PART_DEFINITION_REFERENCES, params));
    }
}